import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./ServiceInformation.css";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import url from "../../Utility/URL/url.js";
import Select from "react-select";
import DOMPurify from "dompurify";
import { getPriceUtility } from "../../Utility/Price/getPriceUtility";

const ServiceInformation = ({ advisorInfo, serviceInformation }) => {
  const [error, setError] = useState({ selectedTime: "" });
  const options = { hour12: true, hour: "numeric", minute: "numeric" };
  const timezones = require("../../Utility/timezone.js");
  const userMachineTime = new Date()
    .toLocaleTimeString("en-US", options)
    .toLowerCase();

  const username = advisorInfo.profileData.username;
  const defaultOption = {
    label: "(UTC +05:30) India (IST)",
    value: "Asia/Kolkata",
  };
  const [skeleton, setSkeleton] = useState(true);
  const [serviceFeedback, setServiceFeedback] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState(null);

  const navigate = useNavigate();
  const [selectDate, setSelectDate] = useState(new Date());

  // const location = useLocation();
  // const { advisorInfo, serviceInformation } = location.state;

  let [timeSlot, setTimeSlot] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");

  let formattedDate = moment(selectDate.$d).format("YYYY-MM-DD");
  let weekDayName = moment(selectDate.$d).format("dddd");

  function getWeekFromAvailability(json) {
    const { availability } = json;
    //now availability is an array of json's need to find the right week.

    const startEnd = availability.find((week) => {
      if (week[weekDayName.toUpperCase()]) {
        return true;
      }
    });

    return startEnd ? startEnd[weekDayName.toUpperCase()] : "";
  }

  const advisiorStartingTime = getWeekFromAvailability(advisorInfo).startTime;

  timezones.sort((a, b) => {
    const nameA = a.label.toUpperCase();
    const nameB = b.label.toUpperCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return 0;
  });

  const isWeekend = (date) => {
    const day = date.day();
    // 0-> sunday
    //6 -> saturday.

    const options = { weekday: "long" }; // set the 'weekday' option to get the full weekday name
    const weekName = date.$d.toLocaleDateString("en-US", options); // get the week name in English (you can change 'en-US' to your desired locale)
    // console.log(weekName); // output: "Monday" (or the current weekday name depending on the date)
    const { availability } = advisorInfo;

    const week = availability.find((week) => {
      const [firstKey] = Object.keys(week);
      if (firstKey.toLowerCase() === weekName.toLowerCase()) {
        return true;
      }
    });
    if (week[weekName.toLowerCase()]) {
      return false;
    } else {
      return true;
    }
  };

  function filterFeedBack() {
    const { userReviews } = advisorInfo;

    return userReviews.filter(
      (review) =>
        review.platformServiceId === serviceInformation.platformServiceId
    );
  }
  //when the user select another date then some api should call.

  useEffect(() => {
    //filter the feedbacks for the selected serviceId.
    setServiceFeedback(filterFeedBack());
    let finalStartTime = roundTime(userMachineTime);

    // startTime is also depend on the availability time
    let jsonObject = {
      advisorTimezone: advisorInfo.profileData.timezone,
      date: moment(new Date()).format("YYYY-MM-DD"),
      userSelectedTimezone: selectedTimeZone
        ? selectedTimeZone.value
        : "Asia/Kolkata",
      startTime: advisiorStartingTime,
      endTime: getWeekFromAvailability(advisorInfo).endTime,
      timeInterval: serviceInformation.callDuration,
      localTime: finalStartTime,
      userSystemCurrentDate: moment(new Date()).format("YYYY-MM-DD"),
      username: username,
    };

    axios
      .post(url.POST_AVAILABLE_TIME_SLOT, jsonObject)
      .then((res) => {
        setTimeSlot(res.data.availableTimeSlots);
        setSkeleton(false);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    //when date changes the selected time will get back to be unselected.
    setSelectedTime("");
    setSkeleton(true);
    //if date is not today's then the start Time need to be send as the advisior time.
    // startTime is also depend on the availability time
    // let finalStartTime = roundTime(userMachineTime);
    let finalStartTime = roundTime(userMachineTime);
    let jsonObject = {
      advisorTimezone: advisorInfo.profileData.timezone,

      date: formattedDate,
      userSelectedTimezone: selectedTimeZone
        ? selectedTimeZone.value
        : "Asia/Kolkata",
      startTime: advisiorStartingTime,
      endTime: getWeekFromAvailability(advisorInfo).endTime,
      timeInterval: serviceInformation.callDuration,
      localTime: finalStartTime,
      userSystemCurrentDate: moment(new Date()).format("YYYY-MM-DD"),
      username: username,
    };

    axios
      .post(url.POST_AVAILABLE_TIME_SLOT, jsonObject)
      .then((response) => {
        setTimeSlot(response.data.availableTimeSlots);
        setSkeleton(false);
      })
      .catch((error) => console.log("Error comes at time slot api ", error));
  }, [formattedDate]);

  useEffect(() => {
    setSelectedTime("");
    let finalStartTime = roundTime(userMachineTime);

    setSkeleton(true);
    // startTime is also depend on the availability time
    let jsonObject = {
      advisorTimezone: advisorInfo.profileData.timezone,

      date: formattedDate,
      userSelectedTimezone: selectedTimeZone
        ? selectedTimeZone.value
        : "Asia/Kolkata",
      startTime: advisiorStartingTime,
      endTime: getWeekFromAvailability(advisorInfo).endTime,
      timeInterval: serviceInformation.callDuration,
      localTime: finalStartTime,
      userSystemCurrentDate: moment(new Date()).format("YYYY-MM-DD"),
      username: username,
    };
    axios
      .post(url.POST_AVAILABLE_TIME_SLOT, jsonObject)
      .then((res) => {
        setTimeSlot(res.data.availableTimeSlots);
        setSkeleton(false);
      })
      .catch((err) => console.log(err));
  }, [selectedTimeZone]);
  function handleDateChange(newValue) {
    setSelectDate(newValue);
  }
  const handleChange = (selectedTimeZone) => {
    setSelectedTimeZone(selectedTimeZone);
  };

  const handleConfirmationButton = () => {
    serviceInformation.bookingDate = formattedDate;
    serviceInformation.bookingTime = selectedTime;
    serviceInformation.timeZone = selectedTimeZone
      ? selectedTimeZone
      : { label: "(UTC +05:30) India (IST)", value: "Asia/Kolkata" };
    serviceInformation.userSelectedDate = selectDate.$d
      ? selectDate.$d
      : selectDate;
    serviceInformation.currency = advisorInfo.profileData.currency;
    serviceInformation.advisiorInformation = advisorInfo;

    if (selectedTime) {
      navigate(`/${username}/${serviceInformation.platformServiceId}/payment`, {
        state: serviceInformation,
      });
    } else {
      setError({
        selectedTime: "You need to select some time for your meeting.",
      });
    }
  };

  function goBackToPreviousPage() {
    navigate(-1);
  }

  function roundTime(time) {
    var timeParts = time.split(/[:\s]/);
    var hours = parseInt(timeParts[0], 10);
    var minutes = parseInt(timeParts[1], 10);
    var ampm = timeParts[2].toUpperCase();
    if (ampm === "PM" && hours < 12) {
      hours += 12;
    } else if (ampm === "AM" && hours === 12) {
      hours = 0;
    }
    var roundedMinutes = Math.ceil(minutes / 5) * 5;
    if (roundedMinutes === 60) {
      roundedMinutes = 0;
      hours++;
    }
    if (hours === 0) {
      hours = 12; // handle midnight as 12 AM
    } else if (hours > 12) {
      hours -= 12; // convert to 12-hour format
    }
    hours = hours < 10 ? "0" + hours : hours;
    var roundedHours =
      hours + ":" + (roundedMinutes < 10 ? "0" : "") + roundedMinutes;
    return roundedHours + " " + ampm;
  }
  //when user select time or date or timezone the time slot api will be called.

  function getDescription(description) {
    return DOMPurify.sanitize(description);
  }
  return (
    <div className="video-call">
      <div className="container">
        <div className="service rounded border">
          <div className="container service-all-information">
            <div className="row">
              <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6 service-details">
                {/* left-heading section  */}
                <div className="row common-nav">
                  <div className="col-xl-6 ant-col-md-6">
                    <div
                      className="d-flex flex-row align-items-center"
                      style={{ gap: "2rem" }}
                    >
                      <div className="flex-item" onClick={goBackToPreviousPage}>
                        <span className="Go-back">
                          <i className="fa-solid fa-arrow-left"></i>
                        </span>
                      </div>
                      <div className="flex-item">
                        <img
                          className="rounded-circle"
                          src="avatar"
                          alt="Avatar"
                        />
                      </div>
                      <div className="flex-item">
                        <span className="service-user-name">
                          {advisorInfo.profileData.fullName}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* service detail section */}
                <div className="row service-detail-section">
                  <div className="title-section col-xl-12 col-md-12 col-lg-12">
                    <h2 className="service-title">
                      {serviceInformation.title}
                    </h2>
                  </div>
                </div>
                {/* price and minutes */}
                <div className="row service-detail-footer">
                  <div className="service-charge col-xl-6 col-lg-6 col-md-6">
                    <div className="booking-price-footer">
                      <span>
                        {getPriceUtility(
                          advisorInfo.profileData.currency,
                          serviceInformation.price
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="service-duration col-xl-6 col-lg-6 col-md-6">
                    <div className="booking-minutes-footer">
                      <span className="service-icon">
                        <i className="fa-solid fa-video"></i>
                      </span>

                      <span>{serviceInformation.callDuration} mins</span>
                    </div>
                  </div>
                </div>

                <div className="service-description">
                  <div
                    className="text-center"
                    dangerouslySetInnerHTML={{
                      __html: getDescription(serviceInformation.description),
                    }}
                  ></div>
                </div>

                {/* feedbacks */}

                {serviceFeedback.length === 0 ? (
                  ""
                ) : (
                  <div className="feedback-container">
                    <div className="row">
                      <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                        <h2 className="feedback-title">Feedback</h2>
                      </div>
                    </div>

                    <div className="slider-section">
                      <Swiper
                        spaceBetween={50}
                        slidesPerView={3}
                        onSlideChange={() => console.log("slide change")}
                        onSwiper={(swiper) => console.log("swiper change")}
                      >
                        <div className="row">
                          {serviceFeedback.map((review, index) => {
                            return (
                              <div className="col-4" key={review.id}>
                                <SwiperSlide key={index}>
                                  <div className="card feedback-card shadow-none border rounded">
                                    <div
                                      className="card-body"
                                      style={{ width: "100%" }}
                                    >
                                      <div className="card-title">
                                        <div className="feedback-title feedback-name-of-person">
                                          <div className="row">
                                            <div className="col-lg-4">ic</div>
                                            <div className="col-lg-8">
                                              <h6 className="text-dark fs-5">
                                                {review.name}
                                              </h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <p className="card-text">
                                        {review.description}
                                      </p>
                                    </div>
                                  </div>
                                </SwiperSlide>
                              </div>
                            );
                          })}
                        </div>
                      </Swiper>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6 service-slots">
                {/* right heading section */}
                <div className="slots-header">
                  <div className="heading fs-3">When should we meet ?</div>
                </div>

                {/* calendar api  */}
                <div className="calendar-container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="main-calendar">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <StaticDatePicker
                            orientation="portrait"
                            openTo="day"
                            value={selectDate}
                            shouldDisableDate={isWeekend}
                            onChange={handleDateChange}
                            minDate={new Date()}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Time selection  */}

                <div className="time-selection-container mt-5 mb-3">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-lg-12">
                            <label className="form-label-title-field mb-2">
                              Select Time
                            </label>
                          </div>
                        </div>

                        <div className="d-flex flex-row flex-wrap justify-content-between">
                          {skeleton ? (
                            <React.Fragment>
                              <button
                                className="btn btn-lg skeleton"
                                style={{ width: "4em", height: "2rem" }}
                              ></button>
                              <button
                                className="btn btn-lg skeleton"
                                style={{ width: "4em", height: "2rem" }}
                              ></button>
                              <button
                                className="btn btn-lg skeleton"
                                style={{ width: "4em", height: "2rem" }}
                              ></button>
                              <button
                                className="btn btn-lg skeleton"
                                style={{ width: "4em", height: "2rem" }}
                              ></button>
                              <button
                                className="btn btn-lg skeleton"
                                style={{ width: "4em", height: "2rem" }}
                              ></button>
                            </React.Fragment>
                          ) : timeSlot.length === 0 ? (
                            <span className="text-danger">
                              All Slots are booked for today you can try booking
                              slots for tommorow
                            </span>
                          ) : (
                            <React.Fragment>
                              {" "}
                              {timeSlot.map((time, index) => {
                                return (
                                  <div
                                    className={`calls-button-${index} m-0 p-0`}
                                    key={index}
                                  >
                                    <button
                                      className={`${
                                        selectedTime === time
                                          ? "btn btn-selected-time mt-2"
                                          : "btn btn-light mt-2"
                                      }`}
                                      value={time}
                                      onClick={() => {
                                        setSelectedTime(time);
                                      }}
                                    >
                                      {time}
                                    </button>
                                  </div>
                                );
                              })}
                            </React.Fragment>
                          )}
                        </div>

                        <span className="text-danger">
                          {error.selectedTime ? error.selectedTime : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="timezone">
                  <Select
                    value={selectedTimeZone ? selectedTimeZone : defaultOption}
                    onChange={handleChange}
                    options={timezones}
                    isSearchable={true}
                    // defaultValue={defaultOption}
                  />
                </div>

                <div className="confirmation-button mt-3">
                  <button
                    className="btn btn-lg btn-primary"
                    onClick={handleConfirmationButton}
                  >
                    Confirm Button
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceInformation;
