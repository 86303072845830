import React from 'react'
import HeaderSection from '../header'
import Footer from '../footer'
import "./contactUs.css";

const ContactUs = () => {
    return (
        <>
            <HeaderSection />
            <section className="contact-section">
                <div className="container">
                    <h2>Let's connect!</h2>
                    <div className="contact-cards">
                        <div className="contact-card">
                            <div className="contact-content">
                                <h3>Reach out</h3>
                                <a href='mailTo:support@advisingbuddy.com'>support@advisingbuddy.com</a>
                            </div>
                            <div className="contact-icon">
                                <i className="fas fa-phone-alt"></i>
                            </div>
                        </div>

                        <div className="contact-card">
                            <div className="contact-content">
                                <h3>Delhi</h3>
                                <p>B-6 East Azad Nagar, Krishna Nagar, Delhi - 110051</p>
                            </div>
                            <div className="contact-icon">
                                <i className="fas fa-map-marker-alt"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default ContactUs