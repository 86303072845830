import React from "react";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { useEffect } from "react";

function Editor({ value, onEditorChange }) {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ size: ["small", false, "large", "huge"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["link"],
    ],
  };
  const { quill, quillRef } = useQuill({ modules });

  useEffect(() => {
    return () => console.log("editor component unmounted");
  }, []);
  useEffect(() => {
    console.log("I am here", value);
    if (quill && value) {
      const content = quill.clipboard.convert(value);
      quill.setContents(content);
    }
  }, [quill]);
  useEffect(() => {
    if (quill) {
      quill.on("text-change", () => {
        const content = quill.root.innerHTML;
        onEditorChange(content);
      });
    }
  }, [quill, quillRef, onEditorChange]);

  return <div ref={quillRef} style={{ height: "10rem" }}></div>;
}
export default Editor;
