import React, { useState, useEffect } from "react";
import "./Profile.css";
import url from "../../../Utility/URL/url";
import axios from "axios";
import Editor from "../../../Utility/textEditor/editor";
import { toast } from "react-toastify";
import Loader from "../../../Utility/loader/Loader";
import getUsernameFromSession from "../../../Utility/gettingInformation/getUsernameFromSession";
import authHeader from "../../../headerTokenUtility/auth.header";
import "react-toastify/dist/ReactToastify.css";
function Profile() {
  const currency = [
    { value: "USD", label: "USD" },
    { value: "EUR", label: "EUR" },
    { value: "GBP", label: "GBP" },
    { value: "JPY", label: "JPY" },
    { value: "INR", label: "Rs." },
  ];
  const [profileDetails, setProfileDetails] = useState(null);
  const [originalProfileDetails, setOriginalProfileDetails] = useState(null);
  const username = getUsernameFromSession();

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(true);

  useEffect(() => {
    async function getProfileDetails(username) {
      try {
        const response = await axios.post(
          url.GET_PROFILE_DETAILS + `?username=${username}`,
          {
            headers: authHeader(),
          }
        );

        setProfileDetails((currValue) =>
          currValue !== response.data ? response.data : currValue
        );
        setOriginalProfileDetails((currValue) =>
          currValue !== response.data ? response.data : currValue
        );
        setLoading(() => false);
      } catch (error) {
        toast.warning(
          "Technical Error while fetching the profile Details.. Please retry again!",
          { position: toast.POSITION.TOP_RIGHT }
        );
      }
    }
    getProfileDetails(username);

    return () => {
      //Cancel any pending requests

      console.log("profile component unmount");
    };
  }, []);

  const handleChange = (event) => {
    let { name, value } = event.target;

    setProfileDetails((currProfileDetails) => {
      return { ...currProfileDetails, [name]: value };
    });
  };
  const handleAboutEditor = (content) => {
    setProfileDetails(() => ({ ...profileDetails, about: content }));
  };

  const handleShortDescription = (content) => {
    setProfileDetails(() => ({ ...profileDetails, description: content }));
  };
  const handleCurrencyChange = (event) => {
    const selectedCurrency = event.target.value;

    setProfileDetails((currProfileDetails) => ({
      ...currProfileDetails,
      currency: selectedCurrency,
    }));
  };
  function updateProfile() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.put(
          url.PUT_UPDATE_PROFILE_DETAILS,
          profileDetails,
          {
            headers: authHeader(),
          }
        );

        if (response && response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
      }
    });
  }
  const handleCancelButton = () => {
    setProfileDetails((currProfile) => originalProfileDetails);
  };
  const handleValidation = () => {
    const { firstName, lastName, description, about, currency } =
      profileDetails;

    let errors = {};
    errors.firstName = firstName ? "" : "Enter FirstName here..";
    errors.lastName = lastName ? "" : "Enter LastName here...";
    errors.description = description
      ? description === "<p><br></p>"
        ? "Enter description here..."
        : ""
      : "Enter description here...";
    errors.about = about
      ? about === "<p><br></p>"
        ? "Enter about yourself here..."
        : ""
      : "Enter about yourself here...";
    errors.currency = currency ? "" : "Select your currency here..";

    return errors;
  };
  const handleUpdateButton = () => {
    const errors = handleValidation();

    let keys = Object.keys(errors);

    let count = keys.reduce((acc, curr) => (errors[curr] ? acc + 1 : acc), 0);

    console.log(count);
    if (count === 0) {
      console.log(updateProfile());
      toast
        .promise(updateProfile(), {
          pending: "Updating Profile",
          success: "Profile updated successfully!",
          error: "Failed to update profile.Please try again later...",
        });
    } else {
      setErrors(errors);
    }
  };

  return (
    <div className="profile">
      <div className="header">
        <div className="header-title d-flex flex-row">
          <p className="text-start fs-1 fw-bold">Profile</p>
        </div>
      </div>
      <hr />
      <div className="main">
        {loading ? (
          <Loader />
        ) : (
          <div className="row">
            <div className="col-4">
              <div className="main-header">
                <div className="profile-photo">
                  <img
                    style={{
                      width: "200px",
                      height: "200px",
                      objectFit: "cover",
                    }}
                    src="https://w0.peakpx.com/wallpaper/979/89/HD-wallpaper-purple-smile-design-eye-smily-profile-pic-face-thumbnail.jpg"
                    className="img-thumbnail"
                    alt="..."
                  />
                </div>
              </div>

              <form className="row row-cols-lg g-2 mt-2 p-1">
                <div className="advisingbuddy-url">
                  <label className="" for="inlineFormInputGroupUsername">
                    Advising Buddy URL
                  </label>
                  <div className="input-group">
                    <div className="input-group-text">
                      <b>advisingbuddy.com/</b>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="inlineFormInputGroupUsername"
                      value={profileDetails ? profileDetails.username : ""}
                      readOnly
                    />
                  </div>
                </div>
                <div className="names">
                  <div className="row">
                    <div className="col-6">
                      <label className="" for="inlineFormInputGroupUsername">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        value={profileDetails ? profileDetails.firstName : ""}
                        onChange={handleChange}
                      />
                      {errors.firstName ? (
                        <span className="text-danger">{errors.firstName}</span>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-6">
                      <label className="" for="inlineFormInputGroupUsername">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        value={profileDetails ? profileDetails.lastName : ""}
                        onChange={handleChange}
                      />
                      {errors.lastName ? (
                        <span className="text-danger">{errors.lastName}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="short-description">
                  <label className="" for="inlineFormInputGroupUsername">
                    Short Description
                  </label>
                  <Editor
                    value={profileDetails ? profileDetails.description : ""}
                    onEditorChange={handleShortDescription}
                  />
                  {errors.description ? (
                    <span className="text-danger">{errors.description}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="about-yourself">
                  <label className="col-12" for="inlineFormInputGroupUsername">
                    About Yourself
                  </label>

                  <Editor
                    value={profileDetails ? profileDetails.about : ""}
                    onEditorChange={handleAboutEditor}
                  />
                  {errors.about ? (
                    <span className="text-danger">{errors.about}</span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="select-currency">
                  <label className="col-12">Select currency</label>
                  <div className="form-group">
                    <select
                      className="form-control"
                      name="currency"
                      value={profileDetails ? profileDetails.currency : ""}
                      onChange={handleCurrencyChange}
                    >
                      <option value="">Select Currency</option>
                      {currency.map((curr) => (
                        <option key={curr.value} value={curr.value}>
                          {curr.label}
                        </option>
                      ))}
                      ;
                    </select>

                    {errors.currency ? (
                      <span className="text-danger">{errors.currency}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <span className="text-muted">
                    Want to change currency ? Contact us at
                    support@advisingbuddy.com
                  </span>
                </div>

                <div className="social-links">
                  <div className="social-links-title">
                    Add your accounts and display links on your page.
                  </div>

                  <div className="input-group mb-2">
                    <div className="input-group-text">
                      <b>Linkedin</b>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="inlineFormInputGroupUsername"
                      name="linkedInLink"
                      value={profileDetails ? profileDetails.linkedInLink : ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="input-group mb-2">
                    <div className="input-group-text">
                      <b>Twitter</b>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="inlineFormInputGroupUsername"
                      name="twitterLink"
                      value={profileDetails ? profileDetails.twitterLink : ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="input-group mb-2">
                    <div className="input-group-text">
                      <b>Youtube</b>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="inlineFormInputGroupUsername"
                      name="youtubeLink"
                      value={profileDetails ? profileDetails.youtubeLink : ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="input-group">
                    <div className="input-group-text">
                      <b>Instagram</b>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="inlineFormInputGroupUsername"
                      name="instagramLink"
                      value={profileDetails ? profileDetails.instagramLink : ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </form>

              <div className="row">
                <div className="col-lg-12">
                  <div className="update-profile-button-section d-flex flex-row justify-content-between">
                    <div className="update-button">
                      <button
                        className="btn btn-lg btn-success"
                        onClick={handleUpdateButton}
                      >
                        Update
                      </button>
                    </div>

                    <div className="cancel-button">
                      <button
                        className="btn btn-lg btn-secondary-outline border-dark"
                        onClick={handleCancelButton}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4"></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Profile;
