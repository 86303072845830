import React from "react";
import { useState, useEffect } from "react";
import "./AddingServices.css";
import Editor from "../../../Utility/textEditor/editor";
import { useLocation, useNavigate } from "react-router-dom";
import url from "../../../Utility/URL/url";
import constant from "../../../Utility/Constants/constant";
import axios from "axios";
import { toast } from "react-toastify";
import getUsernameFromSession from "../../../Utility/gettingInformation/getUsernameFromSession";
import authHeader from "../../../headerTokenUtility/auth.header";

function AddingService() {
  const username = getUsernameFromSession();
  const location = useLocation();
  const callsButtons = [
    { value: "15" },
    { value: "30" },
    { value: "45" },
    { value: "60" },
    { value: "Custom" },
  ];
  const [serviceJson, setServiceJson] = useState({
    selectedType: "",
    callDuration: "30",
    selectedPrice: "200",
    description: "",
    title: "",
    customCallValue: "",
    customPriceValue: "",
    hideInProfileFlag: false,
  });

  const [edit, setEdit] = useState(false);
  const serviceTypeList = ["1:1 Call", "Webinar", "Text Query"];
  const [errors, setErrors] = useState({});
  const priceButtons = [
    { value: "200" },
    { value: "500" },
    { value: "1000" },
    { value: "Custom" },
  ];
  const navigate = useNavigate();

  function handleToggleButton(event) {
    const { checked } = event.target;
    setServiceJson((currServiceJson) => {
      return { ...currServiceJson, hideInProfileFlag: checked };
    });
  }
  useEffect(() => {
    //checking if the service is true it means we need to enable the edit option.
    const service = location.state ? location.state.service : "";

    if (service) {
      setEdit(true);
      setServiceJson((currValue) => {
        // console.log(service);
        let customCallValue = checkCallDuration(service.callDuration);

        let customPriceValue = checkCustomPrice(service.price);

        //edit page.all the fields need edit values.
        let prePopulatedJson = {
          title: service.title,
          callDuration:
            service.type === constant.TEXT_QUERY
              ? ""
              : customCallValue
              ? "Custom"
              : service.callDuration,
          selectedPrice: customPriceValue ? "Custom" : service.price,
          selectedType:
            service.type === constant.ONE_ON_ONE_CALL
              ? "1:1 Call"
              : service.type === constant.WEBINAR
              ? "Webinar"
              : "Text Query",
          customCallValue:
            service.type === constant.TEXT_QUERY
              ? ""
              : customCallValue
              ? service.callDuration
              : "",
          customPriceValue: customPriceValue ? service.price : "",
          description: service.description,
          hideInProfileFlag: service.hideInProfileFlag,
        };

        return currValue !== prePopulatedJson ? prePopulatedJson : currValue;
      });
    } else {
    }
  }, []);

  // useEffect(() => console.log(serviceJson), [serviceJson]);
  function checkCallDuration(callDuration) {
    let selectedCallValue = callsButtons.find(
      (json) => callDuration == json.value
    );

    return !selectedCallValue;
  }
  function checkCustomPrice(price) {
    let selectedPriceValue = priceButtons.find((json) => price == json.value);
    return !selectedPriceValue;
  }
  function goBack() {
    navigate(-1);
  }

  function handleEditorChange(content) {
    setServiceJson(() => ({ ...serviceJson, description: content }));
  }
  function handleConfirm() {
    let errors = checkValidation();
    let keys = Object.keys(errors);
    let count = keys.reduce((acc, curr) => (errors[curr] ? acc + 1 : acc), 0);

    //object destructuring for serviceJson
    const {
      selectedType,
      selectedPrice,
      callDuration,
      description,
      title,
      customCallValue,
      customPriceValue,
      hideInProfileFlag,
    } = serviceJson;

    if (count === 0) {
      //all fields are okay.
      setErrors((prevErrors) => {
        const newErrors = prevErrors !== errors ? errors : prevErrors;
        return newErrors;
      });

      if (edit) {
        const service = location.state.service;

        let apiUrl = url.PUT_SERVICE_BY_SERVICE_ID + service.platformServiceId;
        console.log(service);
        console.log(service.platformServiceId);
        if (selectedType === "1:1 Call") {
          axios
            .put(
              url.PUT_SERVICE_BY_SERVICE_ID + service.platformServiceId,
              {
                username: username,
                title: title,
                price:
                  selectedPrice !== "Custom" ? selectedPrice : customPriceValue,
                description: description,
                callDuration:
                  callDuration !== "Custom" ? callDuration : customCallValue,
                additionalQuestion: "What is your field",
                hideInProfileFlag: hideInProfileFlag ? 1 : 0,
                type: constant.ONE_ON_ONE_CALL,
                availability: "default",
              },
              {
                headers: authHeader(),
              }
            )
            .then((response) => {
              if (response.request.status === 200) {
                toast.success("Service is updated at your profile!", {
                  position: toast.POSITION.TOP_CENTER,
                });
                navigate(-1);
              }
            });
        } else if (selectedType === "Webinar") {
          axios
            .put(
              url.PUT_SERVICE_BY_SERVICE_ID + `${service.platformServiceId}`,
              {
                username: username,
                title: title,
                price:
                  selectedPrice !== "Custom" ? selectedPrice : customPriceValue,
                description: description,
                callDuration:
                  callDuration !== "Custom" ? callDuration : customCallValue,
                additionalQuestion: "What is your field",
                hideInProfileFlag: hideInProfileFlag ? 1 : 0,
                type: constant.WEBINAR,
                availability: "default",
              },
              {
                headers: authHeader(),
              }
            )
            .then((response) => {
              if (response.request.status === 200) {
                toast.success("Service is updated at your profile!", {
                  position: toast.POSITION.TOP_CENTER,
                });
                navigate(-1);
              }
            })
            .catch((error) => {
              toast.warning("API is not added yet", {
                position: toast.POSITION.TOP_CENTER,
              });
            });
        } else {
          // for text query api.
          axios
            .put(
              url.PUT_SERVICE_BY_SERVICE_ID + `${service.platformServiceId}`,
              {
                username: username,
                title: title,
                price:
                  selectedPrice !== "Custom" ? selectedPrice : customPriceValue,
                description: description,
                additionalQuestion: "What is your field",
                hideInProfileFlag: hideInProfileFlag ? 1 : 0,
                type: constant.TEXT_QUERY,
                availability: "default",
              },
              {
                headers: authHeader(),
              }
            )
            .then((response) => {
              if (response.request.status === 200) {
                toast.success("Service is updated at your profile!", {
                  position: toast.POSITION.TOP_CENTER,
                });
                navigate(-1);
              }
            });
        }
      } else {
        if (selectedType === "1:1 Call") {
          axios
            .post(
              url.POST_SERVICE_ADD,
              {
                username: username,
                title: title,
                price:
                  selectedPrice !== "Custom" ? selectedPrice : customPriceValue,
                description: description,
                callDuration:
                  callDuration !== "Custom" ? callDuration : customCallValue,
                additionalQuestion: "What is your field",
                hideInProfileFlag: hideInProfileFlag ? 1 : 0,
                type: constant.ONE_ON_ONE_CALL,
                availability: "default",
              },
              {
                headers: authHeader(),
              }
            )
            .then((response) => {
              if (response.request.status === 200) {
                toast.success("Service is added to your profile!", {
                  position: toast.POSITION.TOP_CENTER,
                });
                navigate(-1);
              }
            });
        } else if (selectedType === "Webinar") {
          axios
            .post(
              url.POST_SERVICE_ADD,
              {
                username: username,
                title: title,
                price:
                  selectedPrice !== "Custom" ? selectedPrice : customPriceValue,
                description: description,
                callDuration:
                  callDuration !== "Custom" ? callDuration : customCallValue,
                additionalQuestion: "What is your field",
                hideInProfileFlag: hideInProfileFlag ? 1 : 0,
                type: constant.WEBINAR,
                availability: "default",
              },
              {
                headers: authHeader(),
              }
            )
            .then((response) => {
              if (response.request.status === 200) {
                toast.success("Service is added to your profile!", {
                  position: toast.POSITION.TOP_CENTER,
                });
                navigate(-1);
              }
            })
            .catch((error) => {
              toast.warning("API is not added yet", {
                position: toast.POSITION.TOP_CENTER,
              });
            });
        } else {
          // for text query api.
          axios
            .post(
              url.POST_SERVICE_ADD,
              {
                username: username,
                title: title,
                price:
                  selectedPrice !== "Custom" ? selectedPrice : customPriceValue,
                description: description,

                additionalQuestion: "What is your field",
                hideInProfileFlag: hideInProfileFlag ? 1 : 0,
                type: constant.TEXT_QUERY,
                availability: "default",
              },
              {
                headers: authHeader(),
              }
            )
            .then((response) => {
              if (response.request.status === 200) {
                toast.success("Service is added to your profile!", {
                  position: toast.POSITION.TOP_CENTER,
                });
                navigate(-1);
              }
            });
        }
      }
    } else {
      //need to setThe Errors to this erros json there  are some errors need to be displayed.

      setErrors((prevErrors) => {
        const newErrors = prevErrors !== errors ? errors : prevErrors;
        return newErrors;
      });
    }
  }
  function checkValidation() {
    let errors = {};

    const {
      selectedPrice,
      callDuration,
      description,
      title,
      customCallValue,
      customPriceValue,
    } = serviceJson;

    errors.title = !title ? "Title must be entered!" : "";

    errors.description = !description ? "Description is mandatory" : "";

    if (callDuration === "Custom") {
      if (customCallValue) {
        const value = parseInt(customCallValue, 10);
        if (!isNaN(value) && value >= 10 && value <= 120) {
          errors.customCallValue = "";
        } else {
          errors.customCallValue =
            "Please enter call duration between 10-120 minutes.";
        }
      } else {
        errors.customCallValue = "Field is required!";
      }
    }

    if (selectedPrice === "Custom") {
      if (customPriceValue) {
        errors.customPriceValue = "";
      } else {
        errors.customPriceValue = "Field is required!";
      }
    }

    return errors;
  }

  useEffect(() => {
    setErrors({});
  }, [serviceJson.selectedType]);

  //returning the form for selected type
  function getForm() {
    return (
      <div className="ServiceForm d-flex flex-column flex-wrap">
        <div className="form-group">
          <label className="form-label-title-field mb-2">Title</label>
          <input
            type="text"
            className="form-control"
            id="title-field"
            name="title"
            placeholder="Name of Service"
            value={serviceJson.title}
            onChange={handleChange}
          />
          {errors.title ? (
            <span className="text-danger">{errors.title}</span>
          ) : (
            ""
          )}
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <label className="form-label" title="Description">
                Description
              </label>
            </div>
            <div className="col-12">
              <div className="quill custom-quill">
                <div className="editor">
                  <Editor
                    value={edit ? serviceJson.description : ""}
                    onEditorChange={handleEditorChange}
                  />
                </div>
                {errors.description ? (
                  <span className="text-danger">{errors.description}</span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        {serviceJson.selectedType !== "Text Query" ? (
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label className="form-label-title-field mb-2">
                  Calls In(Mins)
                </label>
              </div>
            </div>

            <div className="d-flex flex-row flex-wrap justify-content-between">
              {callsButtons.map((bu, index) => (
                <div className={`calls-button-${index} m-0 p-0`} key={index}>
                  <button
                    className={`btn  ${
                      serviceJson.callDuration
                        ? serviceJson.callDuration === bu.value
                          ? "activeButton"
                          : "btn-custom"
                        : "btn-custom"
                    }`}
                    value={bu.value}
                    onClick={() =>
                      setServiceJson({ ...serviceJson, callDuration: bu.value })
                    }
                  >
                    {bu.value === "Custom" ? bu.value : bu.value + " Min"}
                  </button>
                </div>
              ))}
            </div>
          </div>
        ) : (
          ""
        )}

        {serviceJson.callDuration === "Custom" &&
        serviceJson.selectedType !== "Text Query" ? (
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label className="form-label-title-field mb-2">
                  Custom Call Duration (Mins)
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <input
                  className="form-control"
                  type="number"
                  min="10"
                  id="custom-call-ref"
                  name="customCallValue"
                  value={serviceJson.customCallValue}
                  onChange={handleChange}
                />
              </div>
              {errors.customCallValue ? (
                <span className="text-danger">{errors.customCallValue}</span>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}

        {/* Date picker will be setup for webinar later*/}
        {/* <div className="form-group">
          <DatePicker onChange={onChange} value={value} />
        </div> */}

        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <label className="form-label-title-field mb-2">Price (Rs.)</label>
            </div>
          </div>

          <div className="d-flex flex-row flex-wrap justify-content-between">
            {priceButtons.map((bu, index) => (
              <div className={`price-button-${index} m-0 p-0`} key={index}>
                <button
                  className={`btn ${
                    serviceJson.selectedPrice
                      ? serviceJson.selectedPrice === bu.value
                        ? "activeButton"
                        : "btn-custom"
                      : "btn-custom"
                  }`}
                  value={bu.value}
                  style={{ width: "6rem" }}
                  onClick={() =>
                    setServiceJson({ ...serviceJson, selectedPrice: bu.value })
                  }
                >
                  {bu.value === "Custom" ? bu.value : "Rs." + bu.value}
                </button>
              </div>
            ))}
          </div>
        </div>

        {serviceJson.selectedPrice === "Custom" ? (
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label className="form-label-title-field mb-2">
                  Custom Price (Rs.)
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <input
                  className="form-control"
                  type="number"
                  min="1"
                  id="custom-price"
                  name="customPriceValue"
                  value={serviceJson.customPriceValue}
                  onChange={handleChange}
                />
              </div>

              {errors.customPriceValue ? (
                <span className="text-danger">{errors.customPriceValue}</span>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="container text-end">Hide in profile</div>
        <div className="d-flex flex-row justify-content-end">
          <div className="container text-end">
            <label className="switch ">
              <input
                type="checkbox"
                checked={serviceJson.hideInProfileFlag}
                onChange={(event) => handleToggleButton(event)}
              />
              <span className="slider"></span>
            </label>
          </div>
        </div>
        <div className="btn btn-lg btn-dark" onClick={handleConfirm}>
          {edit ? "Update Details" : "Confirm"}
        </div>
      </div>
    );
  }
  function handleChange(event) {
    const { name, value } = event.target;
    setServiceJson((prevState) => ({ ...prevState, [name]: value }));
  }
  return (
    <div className="addingService">
      <div className="header">
        <div className="header-title d-flex flex-row" style={{ gap: "1rem" }}>
          <div className="back-button d-flex flex-column justify-content-center">
            <i
              className="fa-solid fa-arrow-left"
              onClick={goBack}
              style={{ cursor: "pointer" }}
            ></i>
          </div>

          <div className="header-title-add-service">
            <p className="text-start fs-1 fw-bold mt-3">
              {edit ? "Edit Service" : "Add New Service"}
            </p>
          </div>
        </div>
      </div>
      <hr />

      <div className="main">
        <div className="container-fluid new-service-main-container">
          <div className="row">
            <div className="col-6 border rounded">
              {edit ? (
                ""
              ) : (
                <div className="row">
                  <div className="col-12">
                    <div className="Service-Type">
                      <div className="row">
                        <div className="col-12">
                          <div className="Type-heading text-bold">Type</div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="Type-content">
                            <div className="row">
                              {serviceTypeList.map((service, index) => {
                                return (
                                  <div className={`col content-${index}`}>
                                    <button
                                      className={`type-button btn ${
                                        serviceJson.selectedType
                                          ? serviceJson.selectedType === service
                                            ? "activeButton"
                                            : "btn-custom"
                                          : "btn-custom"
                                      }`}
                                      onClick={() =>
                                        setServiceJson({
                                          ...serviceJson,
                                          selectedType: service,
                                        })
                                      }
                                      key={index}
                                    >
                                      {service}
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {serviceJson.selectedType === "1:1 Call"
                ? getForm()
                : serviceJson.selectedType === "Webinar"
                ? getForm()
                : serviceJson.selectedType === "Text Query"
                ? getForm()
                : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddingService;
