import React from "react";
import "./BookingConfirmation.css";
import { useLocation } from "react-router-dom";

const BookingConfirmation = () => {
  const location = useLocation();

  const { state } = location;

  const calendarText =
    state.calendar === "text_query"
      ? "Query Response will be given in 2-3 days"
      : state.calendar;

  return (
    <div className="container-fluid booking-confirmation">
      <div className="container">
        <div className="booking-details container">
          <div className="advisor-name">
            {state.advisorInformation.profileData.fullName}
          </div>
          <div className="divider-horizontal">
            <hr className="hr" />
          </div>
          <div className="details">
            <div className="booking-success-icon">
              <div
                className="fa-solid fa-square-check fa-beat-fade fa-5x "
                style={{ color: "#3f9bf8" }}
              ></div>
            </div>

            <div className="booking-status text-center">
              <p className="h5 text-dark">
                {state.calendar === "text_query"
                  ? "Query sent"
                  : "Booking Confirmed"}
              </p>
            </div>
            <div className="service-title text-center">
              <p className="h5 text-dark">{state.title}</p>
            </div>

            <div
              className="alert alert-success text-center text-bolder"
              role="alert"
            >
              {calendarText}
            </div>
          </div>
          <div className="meeting-info">
            <p className="text-center">
              <small>
                Meeting details are sent to your Email and Mobile number
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmation;
