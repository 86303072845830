import React, { useRef, useState } from "react";
import "./CallsModal.css";
import CalendarAndTimeSlot from "./CalendarAndTimeSlot";
import { getUserMachineTime } from "../../../Utility/userMachineTime/userMachineTime";
import moment from "moment";

function CallsModal({
  callDetails,
  closeModal,
  dateString,
  bookingTime,
  markAsComplete,
  availability,
  declineCall,
  reschedule,
}) {
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const declineValue = useRef("");
  const [error, setError] = useState("");
  function handleDeclineCall() {
    setOpenDeclineModal(() => true);
  }

  function handleReschedule() {
    //we have availability of the user.
    //we also have the calendar and slot as a modal.
    // we need to make a boolean condition so that when user click on resechedule it will render
    // to calendarAndtimeSlot component.

    // if we have availability we can make it true. so the calendar will open.

    if (availability) {
      setOpenCalendar(() => true);
    } else {
      setOpenCalendar(() => false);
    }
  }

  function handleMarkShowDisabled(callDetails) {
    // only give false when the userSystem time will match the end of the meeting time.
    // else give true
    const currentSystemTime = getUserMachineTime();

    const { bookingDate, isShow } = callDetails;
    const bookingTimeArray = bookingTime.split(" - ");
    const [startingTime, endingTime] = bookingTimeArray;
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    let showFinished = false;

    if (currentDate < bookingDate) showFinished = false;
    if (currentDate > bookingDate) showFinished = true;
    if (currentDate === bookingDate) {
      if (currentSystemTime > endingTime) {
        showFinished = true;
      }
      if (currentSystemTime < endingTime) {
        showFinished = false;
      }
      if (currentSystemTime === endingTime) {
        showFinished = true;
      }
    }

    if (isShow && showFinished) {
      return false;
    } else {
      return true;
    }
  }
  function handleDecline() {
    const value = declineValue.current.value;
    const charCount = value.trim().length;

    if (charCount < 20) {
      setError(() => "Reason must be atleast 20 characters long...");
    } else {
      declineCall(callDetails.bookingId, value);
      setError(() => "");
    }
  }
  return (
    <div
      className="modal fade show"
      id="call-details-modal"
      tabIndex="-1"
      style={{ display: "block" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div
              className="call-details-header"
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            ></div>
            <button
              type="button"
              className="btn-close"
              onClick={closeModal}
              aria-label="Close"
            ></button>
          </div>

          {openCalendar ? (
            <CalendarAndTimeSlot
              availability={availability}
              callDetails={callDetails}
              reschedule={reschedule}
            />
          ) : openDeclineModal ? (
            <div className="modal-body">
              <div className="declined-container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="reason-for-declined-call">
                      <label className="form-label">
                        Reason For Decline Call
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <input
                      type="text"
                      className="form-control"
                      ref={declineValue}
                      style={{ backgroundColor: "#d1e7dd" }}
                    />
                  </div>
                  <div className="col-lg-12">
                    {error ? <span className="text-danger">{error}</span> : ""}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="declined-button mt-4">
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={handleDecline}
                      >
                        Decline Call
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="modal-body">
              <div className="details-body" style={{ padding: "20px" }}>
                <div
                  className="for-title fs-4"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  For, <strong>{callDetails.title}</strong>
                </div>
              </div>

              <div className="row calendar-and-time">
                <div
                  className="col-lg-6"
                  style={{
                    border: "1px solid #e3e8ed",
                    padding: "16px 0 16px 24px",
                  }}
                >
                  <span>
                    <i className="fa-solid fa-calendar-days mx-3"></i>
                  </span>
                  <span>
                    <strong>{dateString}</strong>
                  </span>
                </div>
                <div
                  className="col-lg-6"
                  style={{
                    border: "1px solid #e3e8ed",
                    padding: "16px 0 16px 24px",
                  }}
                >
                  <i className="fa-solid fa-clock mx-3"></i>
                  <strong>{bookingTime}</strong>
                </div>
              </div>
              <div
                className="row"
                style={{
                  padding: "10px",
                }}
              ></div>

              <div className="attendee-details row" style={{ padding: "20px" }}>
                <div className="details-attendee-title fs-4">
                  Attendee details
                </div>
              </div>

              <div className="row" style={{ padding: "20px" }}>
                <div className="col-lg-12 attendee-email">
                  <i className="fa-solid fa-envelope mx-3"></i>
                  <strong>{callDetails.cxEmail}</strong>
                </div>
              </div>

              {callDetails.aboutCall && (
                <div className="row" style={{ margin: "20px" }}>
                  <div className="call-about-question">
                    <strong> What is this call about ?</strong>
                  </div>
                  <div
                    className="alert alert-success call-about-solution"
                    role="alert"
                    style={{ margin: "10px" }}
                  >
                    {callDetails.aboutCall}
                  </div>
                </div>
              )}

              <div className="row Reschedule-mark-as-complete">
                <div className="col-lg-6 text-center">
                  <button
                    className="btn btn-sm btn-dark"
                    disabled={callDetails.isCompleted}
                    onClick={handleReschedule}
                  >
                    Reschedule
                  </button>
                </div>
                <div className="col-lg-6 text-center">
                  <button
                    className="btn btn-sm btn-dark"
                    onClick={() => markAsComplete(callDetails)}
                    disabled={handleMarkShowDisabled(callDetails)}
                  >
                    Mark as Complete
                  </button>
                </div>
              </div>
            </div>
          )}
          {!openCalendar && !openDeclineModal && (
            <div className="modal-footer">
              {!callDetails.isCompleted && (
                <div className="col-lg-4 ">
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => handleDeclineCall()}
                  >
                    Decline Call
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CallsModal;
