import React from 'react'

import './termAndConditon.css'
import HeaderSection from '../header'
import Footer from '../footer'

const TermAndConditon = () => {
    return (
        <>
            <HeaderSection />
            <section className="contact-section">
                <div className='mx-lg-5 w-100 termConditon_section'>
                    <h2>Terms and Conditions</h2>
                    <p>Last Updated: October 12, 2024</p>
                    <br/>
                    <p>
                        Welcome to AdvisingBuddy.com (the “Website”). These Terms and Conditions (“Terms”) govern your access to and use of the Website and the services provided by AdvisingBuddy.com (referred to as “we,” “us,” or “our”). By accessing or using our Website, you agree to comply with these Terms. If you do not agree with these Terms, please do not use the Website.
                    </p>
                    <h4><b>1. Acceptance of Terms</b></h4>
                    <p>
                        By using our Website and services, you agree to be bound by these Terms and Conditions, along with our Privacy Policy, which is incorporated herein by reference. If you are using the Website on behalf of an organization, you represent and warrant that you have the authority to bind that organization to these Terms.
                    </p>
                    <h4><b>2. Services Overview</b></h4>
                    <p>
                        AdvisingBuddy.com provides a platform where influencers, educators, mentors, and other professionals can offer 1:1 video meetings or calls with their audience. The services offered through the Website are subject to the terms outlined below.
                    </p>
                    <h4><b>3. User Accounts</b></h4>
                    <p>
                        To access certain features of the Website, you must create an account. You are responsible for maintaining the confidentiality of your account information, including your username and password. You agree to notify us immediately of any unauthorized use of your account. We are not responsible for any losses arising from unauthorized use of your account.
                    </p>
                    <h4><b>4. User Conduct</b></h4>
                    <p>
                        You agree not to use the Website for any unlawful or prohibited purpose. You shall not:
                        <ul>
                            <li>Use the Website in a way that could damage, disable, or impair it.</li>
                            <li>Attempt to gain unauthorized access to any part of the Website.</li>
                            <li>Use the Website to harass, abuse, or harm another individual.</li>
                            <li>Upload or share any content that is harmful, defamatory, obscene, or otherwise objectionable.</li>
                        </ul>
                    </p>
                    <h4><b>5. Booking and Payment</b></h4>
                    <p>
                        Booking: Users can schedule 1:1 video meetings or calls with advisors through the Website. The availability of advisors is subject to change and is at their discretion.
                    </p>
                    <p>Payment: All payments for services are processed through our third-party payment processors, such as Razorpay. By making a payment, you agree to comply with the terms and conditions of the payment processor.
                    </p>
                    <p>Refunds: Refunds may be issued at our sole discretion based on the circumstances of each case. To request a refund, please contact us at <b><a className='text-white' href='mailTo:support@advisingbuddy.com'>support@advisingbuddy.com</a></b>.</p>

                    <h4><b>6. Intellectual Property Rights</b></h4>
                    <p>
                        All content on the Website, including text, graphics, logos, images, and software, is the property of AdvisingBuddy.com or its content suppliers and is protected by copyright, trademark, and other intellectual property laws. You may not use any content from the Website without our prior written permission.
                    </p>

                    <h4><b>7. Disclaimer of Warranties</b></h4>
                    <p>
                        The Website and services are provided "as is" and "as available" without any warranties of any kind, either express or implied. We do not guarantee that the Website will be error-free, secure, or uninterrupted, or that any defects will be corrected.
                    </p>

                    <h4><b>8. Limitation of Liability</b></h4>
                    <p>
                        To the maximum extent permitted by applicable law, AdvisingBuddy.com and its affiliates, officers, agents, and employees shall not be liable for any direct, indirect, incidental, special, or consequential damages, including loss of profits, data, or other intangible losses, resulting from:
                        <ul>
                            <li>Your use or inability to use the Website.</li>
                            <li>Unauthorized access to or alterations of your transmissions or data.</li>
                            <li>Statements or conduct of any third party on the Website.</li>
                        </ul>
                    </p>

                    <h4><b>9. Indemnification</b></h4>
                    <p>
                        You agree to indemnify, defend, and hold harmless AdvisingBuddy.com, its affiliates, officers, agents, and employees from any claims, liabilities, damages, and expenses, including legal fees, arising out of or related to your use of the Website, violation of these Terms, or infringement of any intellectual property or other right of any person or entity.
                    </p>
                    <h4><b>10. Termination</b></h4>
                    <p>
                        We reserve the right to terminate or suspend your access to the Website at our discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users of the Website, us, or third parties, or for any other reason.
                    </p>
                    <h4><b>11. Changes to Terms</b></h4>
                    <p>
                        We may update these Terms from time to time. Any changes will be posted on this page with an updated effective date. By continuing to use the Website after such changes, you agree to the updated Terms.
                    </p>
                    <h4><b>12. Governing Law</b></h4>
                    <p>
                        These Terms and Conditions are governed by and construed in accordance with the laws of India. You agree to submit to the exclusive jurisdiction of the courts located in New Delhi, India for any disputes arising out of or relating to these Terms.
                    </p>
                    <h4><b>13. Contact Us</b></h4>
                    <p>
                        If you have any questions or concerns about these Terms, please contact us at:
                        Email: <b><a className='text-white' href='mailTo:support@advisingbuddy.com'>support@advisingbuddy.com</a></b>
                    </p>

                </div>
            </section >

            <Footer />
        </>
    )
}

export default TermAndConditon