import React, { useEffect, useState, useMemo } from "react";
import "./Queries.css";
import url from "../../../Utility/URL/url";
import axios from "axios";
import Select from "react-select";
import { toast } from "react-toastify";
import { getPriceUtility } from "../../../Utility/Price/getPriceUtility";
import { ReactComponent as PagesNotFound } from "../../../Utility/ErrorPages/PageNotFound.svg";
import getUsernameFromSession from "../../../Utility/gettingInformation/getUsernameFromSession";
import authHeader from "../../../headerTokenUtility/auth.header";
function Queries() {
  const username = getUsernameFromSession();
  let currency = "INR";

  const headerButtons = ["Pending", "Completed"];
  const [selectedHeader, setSelectedHeader] = useState("Pending");
  const [queriesList, setQueriesList] = useState(null);
  const [queryAnswer, setQueryAnswer] = useState("");
  const [errors, setErrors] = useState("");
  const [skeleton, setSkeleton] = useState(true);
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [edit, setEdit] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState();
  const [filteredQueryList, setFilteredQueryList] = useState("");

  const dropdown = useMemo(() => {
    // if queriesList is falsy
    if (!queriesList) return [];

    const uniqueQueries = queriesList.filter(
      (query, index, self) =>
        self.findIndex((obj) => obj.title === query.title) === index
    );
    const dropdownOptions = uniqueQueries.map((value) => ({
      value: value.title,
      label: value.title,
    }));

    dropdownOptions.unshift({ value: "All Query", label: "All Query" });

    return dropdownOptions;
  }, [queriesList]);

  async function getQueriesAndSetQueriesList() {
    try {
      let apiUrl = "";

      apiUrl =
        selectedHeader === "Pending"
          ? url.GET_PENDING_QUERIES
          : url.GET_COMPLETED_QUERIES;

      const response = await axios.get(apiUrl + username, {
        headers: authHeader(),
      });

      setQueriesList(() => response.data);
      setSkeleton(false);
    } catch (error) {
      console.log("Error is coming", error);
    }
  }

  function handleEditResponse(value) {
    setEdit(() => true);
    setQueryAnswer(() => value);
  }
  const handleDropdown = (selected) => {
    setSelectedFilter((currValue) =>
      currValue !== selected ? selected : currValue
    );
  };

  const getUI = () => {
    let showingQueryList = queriesList;
    let query;

    if (filteredQueryList && filteredQueryList.length > 0) {
      showingQueryList = filteredQueryList;
    }

    // the problem is here when we change the value of the queries list we also need to update the selectedQuery answer bro.
    showingQueryList.sort(
      (a, b) => new Date(b.bookingDate) - new Date(a.bookingDate)
    );
    if (selectedQuery) {
      query = queriesList.find(
        (query) => query.queryId === selectedQuery.queryId
      );
      // query = selectedQuery;
    } else {
      query = showingQueryList[0];
    }

    //here the selectedQuery is not changing that's why edit is not working properly.

    //filter based on the selected query in the dropdown
    return (
      <div className="row">
        <div className="col-12">
          {showingQueryList.length > 0 ? (
            <div className="query-sub-main">
              <div className="row">
                <div className="col-lg-2 col-md-2 all-query-section-list">
                  <div className="row">
                    {showingQueryList.length > 0 && (
                      <div className="col-lg-12 col-md-12">
                        <div className="query-dropdown">
                          <Select
                            options={dropdown}
                            value={selectedFilter}
                            onChange={handleDropdown}
                            defaultValue={dropdown[0]}
                          />
                        </div>

                        <div className="query-list-items">
                          {showingQueryList.map((query, index) => {
                            return (
                              <div
                                className="query-item"
                                key={index}
                                onClick={() => {
                                  setSelectedQuery((currQuery) => {
                                    return currQuery !== query
                                      ? query
                                      : currQuery;
                                  });
                                }}
                              >
                                <div className="row">
                                  <div className="col-lg-12 col-md-12">
                                    <div className="query-details">
                                      <div className="query-header d-flex flex-row bd-highlight mb-3 justify-content-between">
                                        <div className="query-name">
                                          {query.cxName}
                                        </div>
                                        <div className="query-date">
                                          {query.bookingDate}
                                        </div>
                                      </div>

                                      <div className="query-description">
                                        <p className="description">
                                          {query.title}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 main-query-section-box">
                  {query && (
                    <div className="query-box">
                      <div className="query-box-header d-flex flex-row">
                        <div className="avatar">
                          <img
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/90/Twemoji_1f600.svg/1200px-Twemoji_1f600.svg.png"
                            className="rounded"
                            height={"50px"}
                          />
                        </div>
                        <div className="userInformation flex-grow-1">
                          <div className="user-name">{query.cxName}</div>
                          <div className="email text-muted fs-6">
                            {query.cxEmail} | {query.phoneNumber}
                          </div>
                        </div>

                        <div className="time&price text-end">
                          <span className="time"></span>
                          <span className="price">
                            {getPriceUtility(currency, query.price)}
                          </span>
                        </div>
                      </div>

                      {selectedHeader === "Completed" ? (
                        <div className="Your-response mt-3">
                          <div className="query-question mt-2 mb-3">
                            {query.queryContent}
                          </div>
                          <div className="row">
                            <div className="col-lg-12">Your Response</div>
                          </div>
                          {!edit ? (
                            <div className="alert alert-success d-flex flex-row justify-content-between">
                              <div className="answer">
                                {query.advisorAnswer}
                              </div>
                              {query.isSeen === 0 && (
                                <div
                                  className="edit-button"
                                  onClick={() =>
                                    handleEditResponse(query.advisorAnswer)
                                  }
                                >
                                  <i className="fa-solid fa-pen-to-square"></i>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="query-edit-response-box">
                              <div className="query-question-answer">
                                <textarea
                                  className="form-control"
                                  id="exampleFormControlTextarea3"
                                  rows="7"
                                  placeholder="Your response"
                                  value={queryAnswer}
                                  onChange={(event) => {
                                    setQueryAnswer((currAnswer) => {
                                      return currAnswer !== event.target.value
                                        ? event.target.value
                                        : currAnswer;
                                    });
                                  }}
                                ></textarea>
                              </div>
                              {errors ? (
                                <span className="text-danger">{errors}</span>
                              ) : (
                                ""
                              )}

                              <div className="submitOrDiscardButton mt-3">
                                <span
                                  className="btn btn-dark mx-3"
                                  onClick={() => handleEditReply(query.queryId)}
                                >
                                  Edit reply
                                </span>
                                <span
                                  className="btn btn-light text-danger border-danger"
                                  onClick={() => setEdit(() => false)}
                                >
                                  Cancel
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="query-box-main">
                          <div className="query-question-asked">
                            {query.queryContent}
                          </div>

                          <div className="query-question-answer">
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea3"
                              rows="7"
                              placeholder="Your response"
                              value={queryAnswer}
                              onChange={(event) => {
                                setQueryAnswer((currAnswer) => {
                                  return currAnswer !== event.target.value
                                    ? event.target.value
                                    : currAnswer;
                                });
                              }}
                            ></textarea>
                          </div>
                          {errors ? (
                            <span className="text-danger">{errors}</span>
                          ) : (
                            ""
                          )}

                          <div className="submitOrDiscardButton mt-3">
                            <span
                              className="btn btn-dark mx-3"
                              onClick={() => handleSendReply(query.queryId)}
                            >
                              Send reply
                            </span>
                            <span className="btn btn-light text-danger border-danger">
                              Reject Query
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="container list-is-empty text-center">
              <div className="Image mt-5 pt-5">
                <PagesNotFound height={"300px"} />
              </div>
              <h1 className="display-5 text-muted">
                Looks like nothing is here!!!
              </h1>
            </div>
          )}
        </div>
      </div>
    );
  };
  useEffect(() => {
    getQueriesAndSetQueriesList();
  }, []);

  useEffect(() => {
    if (selectedFilter && selectedFilter !== "All Query") {
      let filterQueryList = queriesList.filter((query) => {
        return query.title === selectedFilter.value;
      });
      setFilteredQueryList((currList) =>
        currList !== filterQueryList ? filterQueryList : currList
      );
    }
  }, [selectedFilter]);
  useEffect(() => {
    getQueriesAndSetQueriesList();
    if (selectedHeader === "Pending") {
      setEdit(() => false);
      setQueryAnswer(() => "");
    }
    setErrors(() => "");
    setFilteredQueryList(() => null);
    setSelectedFilter(() => undefined);
  }, [selectedHeader]);

  useEffect(() => {
    if (filteredQueryList && filteredQueryList.length > 0) {
      setSelectedQuery(() => filteredQueryList[0]);
    } else {
      setSelectedQuery(() => null);
    }
  }, [filteredQueryList]);
  useEffect(() => {
    if (selectedQuery) {
      setQueryAnswer((currAnswer) => (currAnswer !== "" ? "" : currAnswer));
    }
    setEdit(() => false);
  }, [selectedQuery, queriesList]);

  const handleEditReply = (queryId) => {
    if (edit && queryAnswer) {
      if (queryAnswer.length < 5) {
        setErrors(() => "Answer need to be at least 5 character");
      } else {
        //edit api.
        toast.promise(sendEditResponse(queryId, queryAnswer), {
          pending: "Sending response....",
          success: "Query response send successfully!",
          error: "Failed to send response.Please try again later...",
        });
        setEdit(() => false);
        setQueryAnswer(() => "");
      }
    }

    if (!queryAnswer) setErrors(() => "Answer must not be empty!");
  };
  const sendReplyResponse = (queryId, answer) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          url.PUT_RESPONSE_QUERY,
          {
            queryId: queryId,
            advisorAnswer: answer,
          },
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          resolve(response.data);
          //again i need to fetch the data from the database and set the QueriesList to that.
          getQueriesAndSetQueriesList();
        })
        .catch((error) => reject(error));
    });
  };
  const sendEditResponse = (queryId, answer) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          url.POST_EDIT_RESPONSE_QUERY,
          {
            queryId: queryId,
            advisorAnswer: answer,
          },
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          resolve(response.data);

          getQueriesAndSetQueriesList();
        })
        .catch((error) => reject(error));
    });
  };
  const handleSendReply = (queryId) => {
    if (!edit && queryAnswer) {
      if (queryAnswer.length < 5) {
        setErrors(() => "Answer need to be at least 5 characters");
      } else {
        toast.promise(sendReplyResponse(queryId, queryAnswer), {
          pending: "Sending response....",
          success: "Query response send successfully!",
          error: "Failed to send response.Please try again later..",
        });

        setFilteredQueryList(() => "");
        setQueryAnswer(() => "");
      }
    }

    if (!queryAnswer) setErrors(() => "Answer must not be empty!");
  };

  const handleChangeButton = (value) => {
    if (value !== selectedHeader) {
      setSkeleton(true);
    }

    setSelectedHeader((currValue) => (currValue !== value ? value : currValue));
  };
  return (
    <div className="queries">
      <div className="header">
        <div className="header-title d-flex flex-row">
          <p className="text-start fs-1 fw-bold">Queries</p>
        </div>

        <div className="header-buttons">
          <div
            className="d-flex flex-row flex-wrap justify-content-start"
            style={{ gap: "1rem" }}
          >
            {headerButtons.map((btn) => (
              <button
                className={`btn btn-outline-secondary rounded-pill hover-shadow text-dark border-dark  ${
                  selectedHeader === btn ? `selected-queries-header` : ""
                }`}
                onClick={() => handleChangeButton(btn)}
                value={btn}
              >
                {btn}
              </button>
            ))}
          </div>
        </div>
      </div>
      <hr />

      <div className="query-main">
        {skeleton ? (
          <div className="loading">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="item-1 skeleton skeleton-text"></div>
                <div className="item-1 skeleton skeleton-text"></div>
                <div className="item-1 skeleton skeleton-text"></div>
                <div className="item-1 skeleton skeleton-text"></div>
                <div className="item-1 skeleton skeleton-text"></div>
                <div className="item-1 skeleton skeleton-text"></div>
              </div>
            </div>
          </div>
        ) : (
          getUI()
        )}
      </div>
    </div>
  );
}

export default Queries;
