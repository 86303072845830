import React, { useEffect, useState } from "react";
import "./Payments.css";
import axios from "axios";
import url from "../../../Utility/URL/url";
import constant from "../../../Utility/Constants/constant";
import SkeletonTable from "./SkeletonTable";
import { getPriceUtility } from "../../../Utility/Price/getPriceUtility";
import authHeader from "../../../headerTokenUtility/auth.header";
import getUsernameFromSession from "../../../Utility/gettingInformation/getUsernameFromSession";

function Payments() {
  const username = getUsernameFromSession();
  const [selectedHeader, setSelectedHeader] = useState(
    constant.ALL_TRANSACTIONS
  );

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const handlingHeaderClick = (value) => {
    //handling here table content calling different api.

    setSelectedHeader((currValue) => (currValue !== value ? value : currValue));
  };

  function returnURLSTR() {
    let urlStr = "";
    urlStr +=
      selectedHeader === constant.ALL_TRANSACTIONS
        ? url.GET_ALL_TRANSACTIONS_DETAILS
        : selectedHeader === constant.WITHDRAWAL
        ? url.GET_ALL_WITHDRAWALS_DETAILS
        : url.GET_ALL_SERVICE_BOOKINGS_DETAILS;

    return urlStr;
  }

  async function setDetails(urlStr, username) {
    try {
      let res = await axios.get(urlStr + username, {
        headers: authHeader(),
      });

      if (res) {
        if (res.data) {
          setTableData(() => res.data);
        }
      }
    } catch (error) {
      console.log(
        "Exception occured while fetching the get-Details for payments Transactions" +
          error
      );
    }
  }

  useEffect(() => {
    setLoading(() => true);

    setDetails(returnURLSTR(), username);
  }, []);
  useEffect(() => {
    setLoading(() => true);

    setDetails(returnURLSTR(), username);
  }, [selectedHeader]);
  useEffect(() => {
    setLoading((currValue) => (currValue !== false ? false : currValue));
  }, [tableData]);

  function getTableBodyRows() {
    if (selectedHeader === constant.ALL_TRANSACTIONS) {
      return tableData
        ? tableData.map((data) => {
            const dateObj = new Date(data.creationDate);
            console.log(data.creationDate);
            const year = dateObj.getFullYear();
            const month = dateObj.getMonth() + 1; // Month is zero-based
            const day = dateObj.getDate();

            const formattedDate = `${year}-${month
              .toString()
              .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;

            return (
              <tr>
                <td>{formattedDate}</td>
                <td>{data.receiptId}</td>
                <td>{data.serviceName}</td>
                <td>{data.payeeName}</td>
                <td>{getPriceUtility(data.currency, data.amount)}</td>
                <td>{data.paymentId}</td>
                <td>{data.paymentStatus}</td>
              </tr>
            );
          })
        : "";
    } else if (selectedHeader === constant.WITHDRAWAL) {
      return tableData
        ? tableData.map((data) => {
            const dateObj = new Date(data.creationDate);
            const year = dateObj.getFullYear();
            const month = dateObj.getMonth() + 1; // Month is zero-based
            const day = dateObj.getDate();

            const formattedDate = `${year}-${month
              .toString()
              .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;

            return (
              <tr>
                <td>{formattedDate}</td>
                <td>{data.withdrawalId}</td>
                <td>{data.amount}</td>
                <td>{data.withdrawalCurrency}</td>
                <td>{data.paymentId}</td>
                <td>{data.withdrawalVia}</td>
                <td>{data.network}</td>
                <td>{data.status}</td>
              </tr>
            );
          })
        : "";
    } else if (selectedHeader === constant.SERVICE_BOOKINGS) {
      return tableData
        ? tableData.map((data) => {
            const dateObj = new Date(data.bookingDate);
            const year = dateObj.getFullYear();
            const month = dateObj.getMonth() + 1; // Month is zero-based
            const day = dateObj.getDate();

            const formattedDate = `${year}-${month
              .toString()
              .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;

            return (
              <tr>
                <td>{formattedDate}</td>
                <td>{data.bookingId}</td>
                <td>{getPriceUtility(data.currency, data.amount)}</td>
                <td>{data.cxName}</td>
                <td>{data.serviceName}</td>
                <td>{data.serviceType}</td>
                <td>{data.bookingState}</td>
              </tr>
            );
          })
        : "";
    } else {
      return "";
    }
  }

  function getTableHeaders() {
    if (selectedHeader === constant.ALL_TRANSACTIONS) {
      return (
        <tr>
          <th scope="col">Date</th>
          <th scope="col">Transaction Id</th>
          <th scope="col">Service</th>
          <th scope="col">Booked By</th>
          <th scope="col">Amount</th>
          <th scope="col">Reference Id</th>
          <th scope="col">Status</th>
        </tr>
      );
    } else if (selectedHeader === constant.WITHDRAWAL) {
      return (
        <tr>
          <th scope="col">Date</th>
          <th scope="col">Transaction Id</th>
          <th scope="col">Amount</th>
          <th scope="col">Currency</th>
          <th scope="col">Reference Id</th>
          <th scope="col">Withdrawal Via</th>
          <th scope="col">Network</th>
          <th scope="col">Status</th>
        </tr>
      );
    } else if (selectedHeader === constant.SERVICE_BOOKINGS) {
      return (
        <tr>
          <th scope="col">Date</th>
          <th scope="col">Booking Id</th>
          <th scope="col">Amount</th>
          <th scope="col">Booked By</th>
          <th scope="col">Service Name</th>
          <th scope="col">Service Type</th>
          <th scope="col">Booking State</th>
        </tr>
      );
    } else {
      return "";
    }
  }
  return (
    <div className="payment-module">
      <div className="payment-header">
        <div className="header-title d-flex flex-row">
          <p className="text-start fs-1 fw-bold">Payments</p>
        </div>

        <hr />
        <div className="payment-body">
          <div className="row">
            <div className="col-lg-10">
              <div className="payment-cards">
                <div className="row">
                  <div
                    className="col-lg-4"
                    style={{ paddingLeft: "12px", paddingRight: "12px" }}
                  >
                    <div className="payment-nav-first balance-cards">
                      <i
                        className="fa-solid fa-eye"
                        width={"30px"}
                        height={"20px"}
                      ></i>
                      <div
                        className="balance-content"
                        style={{ flexGrow: "1" }}
                      >
                        <span
                          className="balance-text fw-bold"
                          style={{ fontSize: "12px" }}
                        >
                          Balance
                        </span>
                        <div className="row">
                          <span className="col-lg-6 balance-value fw-bold fw-lighter">
                            Rs. 105
                          </span>
                          <button
                            className="col-lg-6 btn btn-sm btn-dark withdraw-btn"
                            style={{
                              width: "120px",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                          >
                            Withdraw
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4"
                    style={{ paddingLeft: "12px", paddingRight: "12px" }}
                  >
                    <div className="payment-nav-second balance-cards">
                      <i
                        className="fa-solid fa-hand-holding-dollar"
                        width={"30px"}
                        height={"20px"}
                      ></i>
                      <div
                        className="balance-content"
                        style={{ flexGrow: "1" }}
                      >
                        <span
                          className="pending-completion-text fw-bold"
                          style={{ fontSize: "12px" }}
                        >
                          Pending completion
                        </span>
                        <div className="row">
                          <span className="col-lg-6 balance-value">0</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4"
                    style={{ paddingLeft: "12px", paddingRight: "12px" }}
                  >
                    <div className="payment-nav-third balance-cards">
                      <i
                        className="fa-solid fa-coins"
                        width={"30px"}
                        height={"20px"}
                      ></i>
                      <div
                        className="balance-content"
                        style={{ flexGrow: "1" }}
                      >
                        <span
                          className="earnings-text fw-bold"
                          style={{ fontSize: "12px" }}
                        >
                          Lifetime earnings
                        </span>
                        <div className="row">
                          <span className="col-lg-6 balance-value">
                            Rs. 105
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Table selection header  */}
              <div className="table-content mt-5">
                {loading ? (
                  <SkeletonTable />
                ) : (
                  <React.Fragment>
                    <div className="table-selection-header mt-5 mb-3  d-flex flex-row">
                      <div
                        className="payments-table-tab-selction"
                        style={{ paddingLeft: "16px", paddingRight: "16px" }}
                        onClick={() =>
                          handlingHeaderClick(constant.ALL_TRANSACTIONS)
                        }
                      >
                        <div className="d-flex flex-row">
                          <i className="fa-solid fa-right-left me-3"></i>
                          <div className="">{constant.ALL_TRANSACTIONS}</div>
                        </div>
                      </div>

                      <div
                        className="payments-table-tab-selction"
                        style={{ paddingLeft: "16px", paddingRight: "16px" }}
                        onClick={() =>
                          handlingHeaderClick(constant.SERVICE_BOOKINGS)
                        }
                      >
                        <div className="d-flex flex-row">
                          <i class="fa-solid fa-box me-3"></i>
                          <div className="">{constant.SERVICE_BOOKINGS}</div>
                        </div>
                      </div>

                      <div
                        className="payments-table-tab-selction"
                        style={{ paddingLeft: "16px", paddingRight: "16px" }}
                        onClick={() => handlingHeaderClick(constant.WITHDRAWAL)}
                      >
                        <div className="d-flex flex-row">
                          <i class="fa-solid fa-arrow-trend-up me-3"></i>
                          <div className="">{constant.WITHDRAWAL}</div>
                        </div>
                      </div>
                    </div>
                    <div className="payments-table">
                      <div className="payments-table-content">
                        <table className="table table-bordered table-hover">
                          <thead className="table-light">
                            {getTableHeaders()}
                          </thead>
                          <tbody>{getTableBodyRows()}</tbody>
                        </table>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Payments;
