import React from "react";
import { Navigate } from "react-router-dom";
import gettingDetails from "../../Utility/DataFetching/gettingDetails";
import constant from "../../Utility/Constants/constant";

const PrivateRoute = ({ children }) => {
  let token = "";

  //getttingDetails from session.
  const value = gettingDetails(constant.ABD_SESSION);

  if (value) {
    const object = JSON.parse(value);

    token = object.accessToken;
  }

  return token ? children : <Navigate to="/signin" />;
};

export default PrivateRoute;
