import React from 'react'

import './cancellationPolicy.css'
import HeaderSection from '../header'
import Footer from '../footer'

const CancellationPolicy = () => {
    return (
        <>
            <HeaderSection />
            <section className="contact-section">
                <div className='mx-lg-5 w-100 policy_section'>
                    <h2>Cancellation and Refund Policy</h2>
                    <p>Last Updated: October 12, 2024</p>
                    <br />
                    <p>
                        Thank you for using AdvisingBuddy.com. We aim to provide a seamless experience for scheduling 1:1 video meetings with advisors. This Cancellation and Refund Policy outlines the rules regarding cancellations, rescheduling, and refunds for services purchased through our platform. Please read it carefully before booking any services.

                    </p>
                    <h4><b>1. Cancellation and Rescheduling by Users</b></h4>
                    <p>
                        No Cancellations or Rescheduling: Once a session is booked, users are not permitted to cancel or reschedule the booking. Please ensure that you select a time that suits your availability before confirming a session.
                    </p>
                    <h4><b>2. Cancellation and Rescheduling by Advisors</b></h4>
                    <p>
                        Advisor-Initiated Cancellations: Advisors reserve the right to cancel a session due to unforeseen circumstances. In such cases, users will automatically receive a full refund.
                    </p>
                    <p>
                        Advisor-Initiated Rescheduling: Advisors may also reschedule a session at their discretion, with prior notice to the user. Users will be notified of the new session time.
                    </p>
                    <h4><b>3. Refund Process</b></h4>
                    <p>
                        Eligibility: Refunds are only provided when a session is canceled by the advisor or if the advisor fails to join the scheduled call.
                    </p>
                    <p>
                        Refund Method: Refunds will be processed through the original payment method used at the time of booking.

                    </p>
                    <p>Processing Time: Please allow 7-10 business days for the refund to reflect in your account, depending on your bank or payment provider.</p>
                    <h4><b>4. Non-Refundable Services</b></h4>
                    <p>
                        Since users are not permitted to cancel or reschedule their bookings, all confirmed bookings are considered final and non-refundable, except in cases where the advisor cancels the session or fails to join the call.

                    </p>
                    <h4><b>5. Disputes and Chargebacks</b></h4>
                    <p>
                        If you believe you have been charged incorrectly or have a dispute regarding a payment, please contact us at <b><a className='text-white' href='mailTo:support@advisingbuddy.com'>support@advisingbuddy.com</a></b> within 7 days of the payment date.
                    </p>
                    <p>We will review your claim and work to resolve the issue promptly.
                    </p>
                    <p>Filing a chargeback with your payment provider before contacting us may result in additional fees, which will be deducted from the refund amount if the chargeback is resolved in our favor.</p>

                    <h4><b>6. Contact Us</b></h4>
                    <p>
                        For any questions or concerns regarding this Cancellation and Refund Policy, please contact us at: <b><a className='text-white' href='mailTo:support@advisingbuddy.com'>support@advisingbuddy.com</a></b>
                    </p>
                </div>
            </section >

            <Footer />
        </>
    )
}

export default CancellationPolicy;