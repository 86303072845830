import React, { useState } from 'react';
import './faq.css';
import HeaderSection from '../header';
import Footer from '../footer';

const FAQPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    { question: 'What is Advising Buddy?', answer: 'Advising Buddy is a tool that enables you to connect with your audience through 1:1 sessions - to share your knowledge and monetise your time better. Just create your personal link, add your services, availability and charges; and share your link with your followers. Your followers can now book a call to connect with you' },
    { question: 'Can I conduct paid sessions?', answer: 'Absolutely, we enable you to conduct both free and paid sessions with your followers' },
    { question: 'How much can I charge for my sessions?', answer: "It really depends on how much value you think you're providing and how much are your followers willing to pay. For idea, our creators charge anywhere from $50 to $500 for a session" },
    { question: 'How do the payments work?', answer: 'You can collect payments from anywhere in the world in your preferred currency. Payouts are Instant with the help of our integration with RazorPay' },
    { question: 'Wait, is Advising Buddy really free?', answer: "Yes! We're free to use and there is not hidden charges." },
    { question: 'What are the transaction charges?', answer: 'Payment gateways like Stripe and PayPal typically charge around 3% of the transaction amount. This is beyond our control' }
  ];

  return (
    <>
     <HeaderSection />
     <div className="faq-section">
      <div className="faq-left">
        <h2>Frequently asked questions</h2>
        <p>Can't find the answer you are looking for?</p>
        <a href="/contactus" className="faq-contact-link">Reach out to us</a>
      </div>

      <div className="faq-right">
        {faqData.map((item, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleAccordion(index)}>
              {item.question}
              <span className={`faq-icon ${activeIndex === index ? 'rotate' : ''}`}>&#9662;</span>
            </div>
            {activeIndex === index && <div className="faq-answer">{item.answer}</div>}
          </div>
        ))}
      </div>
    </div>
     <Footer />
    </>
   
  );
};

export default FAQPage;
