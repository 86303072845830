import React from "react";
import "./Rewards.css";

function Rewards() {
  return (
    <div className="rewards">
      <h1>I am at Rewards page.</h1>
    </div>
  );
}

export default Rewards;
