export function roundTime(time) {
  var timeParts = time.split(/[:\s]/);
  var hours = parseInt(timeParts[0], 10);
  var minutes = parseInt(timeParts[1], 10);
  var ampm = timeParts[2].toUpperCase();
  if (ampm === "PM" && hours < 12) {
    hours += 12;
  } else if (ampm === "AM" && hours === 12) {
    hours = 0;
  }
  var roundedMinutes = Math.ceil(minutes / 5) * 5;
  if (roundedMinutes === 60) {
    roundedMinutes = 0;
    hours++;
  }
  if (hours === 0) {
    hours = 12; // handle midnight as 12 AM
  } else if (hours > 12) {
    hours -= 12; // convert to 12-hour format
  }
  hours = hours < 10 ? "0" + hours : hours;
  var roundedHours =
    hours + ":" + (roundedMinutes < 10 ? "0" : "") + roundedMinutes;
  return roundedHours + " " + ampm;
}
