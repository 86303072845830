import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Services.css";
import axios from "axios";
import url from "../../../Utility/URL/url";
import { toast } from "react-toastify";
import constant from "../../../Utility/Constants/constant";
import { getPriceUtility } from "../../../Utility/Price/getPriceUtility";
import authHeader from "../../../headerTokenUtility/auth.header";
import getUsernameFromSession from "../../../Utility/gettingInformation/getUsernameFromSession";
import "react-toastify/dist/ReactToastify.css";

function Services() {
  const navigate = useNavigate();
  const [skeleton, setSkeleton] = useState(true);

  //profile data will have username and currency i want that in each menu.

  const currency = "Rs.";
  const [selectedFilterOption, setSelectedFilterOption] = useState("All");
  //taking the details from session.
  const username = getUsernameFromSession();

  const [filterOptions, setFilterOptions] = useState([
    "All",
    "1:1 Call",
    "Text Query",
    "Webinar",
  ]);
  const [servicesList, setServicesList] = useState([]);

  useEffect(() => {
    //need to set the username first before the api call will do that later
    //it's related to sign in and signup.

    //getting the details of logged in user and taking the username;

    setSkeleton(true);

    if (username) {
      axios
        .get(url.GET_ALL_SERVICE + `?username=${username}`, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data) {
            setServicesList((currValue) =>
              currValue !== response.data ? response.data : currValue
            );
          }

          setSkeleton(false);
        })
        .catch((error) => {
          if (error) {
            toast.error(
              "Network Error Please try again!",

              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
          }
        });
    }

    return () => {
      console.log("Component unmounted");
    };
  }, []);

  function handleSelectedFilterOption(value) {
    setSelectedFilterOption((prevSelectedFilterOption) => {
      const newSelectedFilterOption =
        prevSelectedFilterOption !== value ? value : prevSelectedFilterOption;

      return newSelectedFilterOption;
    });
  }
  // console.log(servicesList);
  useEffect(() => {
    //check what is the selectedOption nd changes the value of the entire list according to that.

    const filterType =
      selectedFilterOption === "1:1 Call"
        ? constant.ONE_ON_ONE_CALL
        : selectedFilterOption === "WEBINAR"
        ? constant.WEBINAR
        : selectedFilterOption === "Text Query"
        ? constant.TEXT_QUERY
        : "All";

    axios
      .post(
        url.GET_SERVICE_BY_USERNAME_AND_TYPE,
        {
          username: username,
          type: filterType,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data) {
          //setThe serviceList.

          console.log(response.data);
          setServicesList((prevServicesList) => {
            const newServicesList =
              prevServicesList !== response.data
                ? response.data
                : prevServicesList;

            return newServicesList;
          });
        }
      })
      .catch((error) => {if (error) {
        toast.error(
          "Network Error Please try again!",

          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }});
  }, [selectedFilterOption]);
  function handleAddClick() {
    //clicking on add a service will navigate to services / add.
    navigate("/dashboard/services/add");
  }

  const deleteService = (serviceId) => {
    //need to check setServiceList after sometime it cannot be in a promise.
    return new Promise((resolve, reject) => {
      axios
        .delete(url.DELETE_SERVICE_URL + `/${serviceId}`, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            axios
              .get(url.GET_ALL_SERVICE + `?username=${username}`)
              .then((response) => {
                setServicesList(response.data);
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  function handleDelete(serviceId) {
    //username need to be checked
    toast.promise(deleteService(serviceId), {
      pending: "Deleting service...",
      success: "Service deleted successfully!",
      error: "Failed to delete service. Please try again.",
    });
  }
  function handleToggleAPI(serviceId, value) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          url.POST_TOGGLE_VALUE +
            `?platformServiceId=${serviceId}&value=${value ? "on" : "off"}`,
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          if (response.status === 200) {
            console.log(
              "toggle hit response got nothing here fyi,",
              response.data
            );
            resolve(response.data);
          }
        })
        .catch((error) => reject(error));
    });
  }
  function handleToggleButton(event, service, index) {
    setServicesList((prevServicesList) => {
      const updatedServiceList = [...prevServicesList];

      updatedServiceList[index] = {
        ...updatedServiceList[index],
        hideInProfileFlag: event.target.checked,
      };
      return updatedServiceList;
    });

    toast.promise(
      handleToggleAPI(service.platformServiceId, event.target.checked),
      {
        pending: "Processing",
        success: "Success",
        error: "Failed",
      }
    );
  }

  return (
    <div className="services">
      <div className="header">
        <div className="header-title d-flex flex-row">
          <p className="text-start fs-1 fw-bold">Services</p>
        </div>

        <div className="header-buttons">
          <div
            className="d-flex flex-row flex-wrap justify-content-start"
            style={{ gap: "1rem" }}
          >
            {filterOptions.map((btn) => (
              <button
                className={`btn btn-outline-secondary rounded-pill hover-shadow text-dark border-dark ${
                  selectedFilterOption === btn ? "active-filter-button" : ""
                }`}
                onClick={() => handleSelectedFilterOption(btn)}
                value={btn}
              >
                {btn}
              </button>
            ))}

            <div className="addServiceButton">
              <Link to="/dashboard/services/add">
                <button className="btn btn-dark">+ Add Services</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div className="main">
        <div className="service-list">
          {skeleton ? (
            <React.Fragment>
              <div className="card service-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-8">
                      <h5 className="card-title skeleton skeleton-text"></h5>
                    </div>

                    <div className="col-lg-4">
                      <div className="edit-delete skeleton skeleton-text"></div>
                    </div>
                  </div>

                  <div className="service-duration-price skeleton skeleton-text mt-2 w-75"></div>

                  <div className="container text-end">
                    <label
                      className="switch skeleton skeleton-text w-25"
                      style={{ height: "3rem" }}
                    ></label>
                  </div>
                </div>
              </div>
              <div className="card service-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-8">
                      <h5 className="card-title skeleton skeleton-text"></h5>
                    </div>

                    <div className="col-lg-4">
                      <div className="edit-delete skeleton skeleton-text"></div>
                    </div>
                  </div>

                  <div className="service-duration-price skeleton skeleton-text mt-2 w-75"></div>

                  <div className="container text-end">
                    <label
                      className="switch skeleton skeleton-text w-25"
                      style={{ height: "3rem" }}
                    ></label>
                  </div>
                </div>
              </div>
              <div className="card service-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-8">
                      <h5 className="card-title skeleton skeleton-text"></h5>
                    </div>

                    <div className="col-lg-4">
                      <div className="edit-delete skeleton skeleton-text"></div>
                    </div>
                  </div>

                  <div className="service-duration-price skeleton skeleton-text mt-2 w-75"></div>

                  <div className="container text-end">
                    <label
                      className="switch skeleton skeleton-text w-25"
                      style={{ height: "3rem" }}
                    ></label>
                  </div>
                </div>
              </div>
              <div className="card service-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-8">
                      <h5 className="card-title skeleton skeleton-text"></h5>
                    </div>

                    <div className="col-lg-4">
                      <div className="edit-delete skeleton skeleton-text"></div>
                    </div>
                  </div>

                  <div className="service-duration-price skeleton skeleton-text mt-2 w-75"></div>

                  <div className="container text-end">
                    <label
                      className="switch skeleton skeleton-text w-25"
                      style={{ height: "3rem" }}
                    ></label>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : servicesList.length === 0 ? (
            <div className="row">
              <div className="col-4">
                <div
                  className="first-service d-flex flex-column flex-wrap border rounded w-100 p-3"
                  style={{ gap: "10px" }}
                >
                  <div className="flex-item first-service-heading">
                    <h2>Add your first service</h2>
                  </div>
                  <div className="flex-item first-service-heading">
                    <p>
                      Experts use advissing buddy for consulting, Mentoring,
                      Quick 15 min calls, Portfolio reviews and much more.
                    </p>
                  </div>
                  <div className="flex-item first-service-heading">
                    <button
                      className="btn btn-large bg-danger text-white"
                      onClick={handleAddClick}
                    >
                      Add a service
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <React.Fragment>
              {servicesList.map((service, index) => {
                return (
                  <div className="card service-card" key={index}>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-8">
                          <h5 className="card-title">{service.title}</h5>
                        </div>

                        <div className="col-lg-4">
                          <div className="edit-delete">
                            <span className="edit-link">
                              <i
                                className="fa-solid fa-pen-to-square "
                                style={{ color: "#0a0c10" }}
                                onClick={() =>
                                  navigate(`/dashboard/services/edit`, {
                                    state: { service: service },
                                  })
                                }
                              ></i>
                            </span>

                            <span className="delete-link">
                              <i
                                className="fa-solid fa-trash-can "
                                style={{ color: "#ea1313" }}
                                onClick={() =>
                                  handleDelete(service.platformServiceId)
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="service-duration-price">
                        <div className="row">
                          <div className="col-lg-12">
                            {service.type === constant.TEXT_QUERY ? (
                              ""
                            ) : (
                              <small className="duration">
                                {service.callDuration} mins |
                              </small>
                            )}

                            <small className="price">
                              {getPriceUtility(currency, service.price)}
                            </small>
                          </div>
                        </div>
                      </div>

                      <div className="container text-end">Hide in profile</div>
                      <div className="d-flex flex-row justify-content-end">
                        <div className="container text-end">
                          <label className="switch ">
                            <input
                              type="checkbox"
                              checked={service.hideInProfileFlag}
                              onChange={(event) =>
                                handleToggleButton(event, service, index)
                              }
                            />
                            <span className="slider"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default Services;
