import React, { useEffect } from "react";
import { useState } from "react";
import "../SignUp/Validator.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import gettingDetails from "../../../Utility/DataFetching/gettingDetails";
const urls = require("../../../Utility/URL/url.js");

function Validator() {
  const [userEmail, setUserEmail] = useState("");
  const navigate = useNavigate();
  const [OTP, setOTP] = useState(new Array(6).fill(""));
  const location = useLocation();
  const { state } = location;
  const [currentResend, setCurrentResend] = useState(1);
  const maxResend = 3;

  //componentDidMount
  useEffect(() => {
    if (state) {
      const { email } = state;
      if (email) setUserEmail(email);
    }
  }, [userEmail]);

  function handleInput(element, index) {
    if (isNaN(element.value)) return false;
    setOTP([...OTP.map((data, ind) => (ind === index ? element.value : data))]);

    //focus next input

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  }

  function handleResend() {
    if (currentResend < maxResend) {
      setCurrentResend((prevResend) => prevResend + 1);

      axios.post(urls.POST_RESEND_OTP_URL, {});
    }
  }
  function validateOTP() {
    //passing the otp as a string.

    // console.log(state);

    axios
      .post(urls.POST_OTP_VALIDATION_URL, {
        email: userEmail,
        otp: OTP.join(""),
      })
      .then((res) => {
        navigate("/dashboard/Home", { state: { userData: state.userData } });
      })
      .catch((err) => {
        toast.error(`${err.response.data.messageString}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }

  return (
    <React.Fragment>
      <div className="container height-100 d-flex justify-content-center align-items-center">
        <div className="position-relative">
          <div className="card p-2 text-center">
            <h6>
              Please enter the one time password
              <br />
              to verify your account
            </h6>
            <div>
              <span>A code has been sent to</span>{" "}
              <small>{location.state.email}</small>
            </div>
            <div
              id="otp"
              className="inputs d-flex flex-row justify-content-center mt-2"
            >
              {OTP.map((data, index) => {
                return (
                  <input
                    className="m-2 text-center form-control rounded"
                    type="text"
                    maxLength="1"
                    name="otp"
                    value={data}
                    onChange={(e) => handleInput(e.currentTarget, index)}
                    onFocus={(e) => e.currentTarget.select()}
                  />
                );
              })}
            </div>
            <div className="mt-4">
              <button
                className="btn btn-danger px-4 validate"
                onClick={validateOTP}
              >
                Validate
              </button>
              <ToastContainer />
            </div>
          </div>
          <div className="card-2">
            <div className="content d-flex justify-content-center align-items-center">
              <span>Didn't get the code</span>
              <span
                className="text-danger ms-3"
                style={{ cursor: "pointer" }}
                onClick={handleResend}
              >
                Resend({currentResend}/{maxResend})
              </span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Validator;
