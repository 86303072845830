import React, { useState } from "react";
import axios from "axios";
import "./SignIn.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import dataStored from "../../../Utility/DataStoring/dataStored";
import constant from "../../../Utility/Constants/constant";

const urls = require("../../../Utility/URL/url.js");

function SignIn() {
  //For state we use a hook called useState.
  const [signInInformation, setSignInInformation] = useState({
    email: "",
    password: "",
    rememberMe: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  //handling all the input fields.
  function handleInput(event) {
    const { name, value, type } = event.currentTarget;

    if (type === "checkbox") {
      // copy the old state and overriding with the new one.
      // [name] is equal to the e.currentTarget.name = value.
      setSignInInformation({
        ...signInInformation,
        [name]: event.currentTarget.checked,
      });
    } else {
      setSignInInformation({ ...signInInformation, [name]: value });
    }
  }

  function handleForgetPassword(event) {
    event.preventDefault();

    if (signInInformation.email) {
      let json = { email: signInInformation.email };
      axios
        .post(urls.POST_FORGET_PASSWORD_URL, json)
        .then((res) => {
          if (res.status === 200) {
            navigate("/validator", {
              state: {
                email: signInInformation.email,
                password: signInInformation.password,
              },
            });
          }
        })
        .catch((err) => alert(err));
    } else {
      setErrors({ ...errors, email: "Enter the Email" });
    }
  }

  function handleSubmission(event) {
    event.preventDefault();

    // checking all the input fields are entred.
    let errors = validateInput();

    // if all the input fields are entred then we can
    // execute below if not then the else part get exectued.
    if (isValid(errors)) {
      let json = {
        username: signInInformation.email,
        password: signInInformation.password,
      };

      axios
        .post(urls.POST_api_auth_signin, json)
        .then((res) => {
          if (res.status === 200) {
            //validate using email password now.

            const key = constant.ABD_SESSION;
            const value = res.data;

            // const tokenType = res.data.tokenType;
            // const accessToken = res.data.accessToken;
            // const refreshToken = res.data.refreshToken;
            // const userName = res.data.username;

            dataStored(key, value);

            navigate("/validator", {
              state: { email: signInInformation.email, userData: res.data },
            });
            // // use successfully logged in you need to navigate to the validator page then user page.
            // navigate("/dashboard/Home", { state: { userData: res.data } });
          }
        })
        .catch((err) =>
          toast.error(`${err.response.data.messageString}`, {
            position: toast.POSITION.TOP_RIGHT,
          })
        );
    } else {
      setErrors(errors);
    }
  }
  function validateInput() {
    let { email, password } = signInInformation;
    let errors = {};

    if (!email) errors.email = "Email is mandatory";
    if (!password)
      errors.password =
        "Password cannot be empty,For your privacy it should be entered";
    return errors;
  }
  function isValid(errors) {
    // errors would have keys with non empty strings as values.
    let keys = Object.keys(errors); //keys in an array.
    let count = keys.reduce((acc, curr) => (errors[curr] ? acc + 1 : acc), 0);
    return count === 0;
  }

  return (
    <React.Fragment>
      <section className="vh-100">
        <div className="container py-5 h-100">
          <div className="row d-flex align-items-center justify-content-center h-100">
            <div className="col-md-8 col-lg-7 col-xl-6">
              <img
                src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
                className="img-fluid"
                alt="loading..."
              />
            </div>
            <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1 bordercustomround2">
              <form>
                <div className="form-outline mb-4">
                  <label className="form-label">Email address</label>
                  <input
                    placeholder="someone@example.com"
                    type="email"
                    id="form1Example13"
                    name="email"
                    value={signInInformation.email}
                    className="form-control form-control-lg"
                    onChange={handleInput}
                  />
                  {errors.email ? (
                    <span className="text-danger">{errors.email}</span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="form-outline mb-4">
                  <label className="form-label">Password</label>
                  <input
                    placeholder="••••••••"
                    type="password"
                    id="form1Example23"
                    name="password"
                    value={signInInformation.password}
                    className="form-control form-control-lg"
                    onChange={handleInput}
                  />
                  {errors.password ? (
                    <span className="text-danger">{errors.password}</span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="d-flex justify-content-evenly align-items-center mb-4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="rememberMe"
                      checked={signInInformation.rememberMe}
                      onChange={handleInput}
                    />
                    <label className="form-check-label marbotcustom1rem"> Remember me </label>
                    <span
                    className="forgetPassword"
                    onClick={handleForgetPassword}
                  >
                    Forgot password?
                  </span>
                  </div>

                  
                </div>

                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg btn-block px-25custom"
                    onClick={handleSubmission}
                  >
                    Sign in
                  </button>
                  <ToastContainer />
                </div>
                <div className="divider d-flex align-items-center my-4">
                  <p className="text-center fw-bold mx-3 mb-0 text-muted">OR</p>
                </div>

                <button className="btn btn-lg btn-block">
                  <i className="fab fa-facebook-f me-2"></i>Continue with
                  Facebook
                </button>
                <button className="btn btn-lg btn-block ">
                  <i className="fab fa-twitter me-2"></i>Continue with Twitter
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default SignIn;
