import React, { useEffect, useState } from "react";
import "./TextQuery.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import url from "../../Utility/URL/url";
import useRazorpay from "react-razorpay";
import constant from "../../Utility/Constants/constant";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
export default function TextQuery() {
  const Razorpay = useRazorpay();
  const location = useLocation();
  const navigate = useNavigate();
  const [serviceInformation, setServiceInformation] = useState();
  const [advisorInformation, setAdvisorInformation] = useState();
  const [errors, setErrors] = useState({});
  const [phone, setPhone] = useState();
  const [formInformation, setFormInformation] = useState({
    username: "",
    email: "",
    query: "",
  });
  let paymentFailedStatus = false;
  useEffect(() => {
    const { serviceInformation, advisorInfo } = location.state;

    setServiceInformation((preServiceValue) =>
      preServiceValue !== serviceInformation
        ? serviceInformation
        : preServiceValue
    );

    setAdvisorInformation((preAdvisorValue) =>
      preAdvisorValue !== advisorInfo ? advisorInfo : preAdvisorValue
    );
  }, []);

  const validateAllFields = () => {
    let errors = {};

    errors.username = !formInformation.username ? "Name is mandatory!" : "";

    if (!formInformation.email) {
      errors.email = "Email is mandatory!";
    } else {
      const emailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!emailRegex.test(formInformation.email)) {
        errors.email = "Invalid email format";
      }
    }

    errors.phone = !phone ? "Phone Number is mandatory!" : "";
    errors.query = !formInformation.query
      ? "Please enter the query you want to ask"
      : "";

    return errors;
  };

  const handlePayment = () => {
    if (serviceInformation.price === "0" || serviceInformation.price === 0) {
      axios
        .post(url.POST_QUERY_TO_ADVISOR, {
          advisorUsername: advisorInformation.profileData.username,
          advisorName: advisorInformation.profileData.fullName,
          platformServiceId: serviceInformation.platformServiceId,
          cxEmail: formInformation.email,
          cxName: formInformation.username,
          phoneNumber: phone,
          paymentStatus: "FREE",
          paymentId: null,
          title: serviceInformation.title,
          queryContent: formInformation.query,
          price: serviceInformation.price,
        })
        .then((response) => {
          toast.success("Query is added to the advisor profile.", {
            position: toast.POSITION.TOP_CENTER,
          });

          const bookingConfirmationData = {
            title: serviceInformation.title,
            calendar: "text_query",
            advisorInformation: advisorInformation,
          };
          const { bookingId } = response.data;
          navigate(
            `/${serviceInformation.platformServiceId}/bookingConfirmation/bookingId=${bookingId}`,
            {
              state: bookingConfirmationData,
            }
          );
        })
        .catch((error) => console.log(error));
    } else {
      axios
        .post(url.POST_PAY_CREATE_ORDER, {
          amount: serviceInformation.price,
          currency: advisorInformation.profileData.currency,
          platformServiceId: serviceInformation.platformServiceId,
          name: formInformation.username,
          emailId: formInformation.email,
          mobileNumber: phone,
          usernameAdvisor: advisorInformation
            ? advisorInformation.profileData.username
            : "",
        })
        .then((response) => {
          const usernameCustomer = response.data.usernameCustmr;
          if (response.status === 200) {
            const options = {
              key: constant.RAZORPAY_key,
              amount: response.data.amount,
              currency: advisorInformation.profileData.currency,
              name: constant.COMPANY_NAME,
              description: serviceInformation.title,
              image: constant.COMPANY_LOGO,
              order_id: response.data.orderId,
              handler: function (response) {
                axios
                  .post(url.POST_PAYMENT_PAY_RESPONSE, {
                    orderId: response.razorpay_order_id,
                    paymentId: response.razorpay_payment_id,
                    status: constant.PAYMENT_RESPONSE_STATUS_SUCCESS,
                    paymentStatus: constant.PAYMENT_STATUS_PAID,
                    usernameAdvisor: advisorInformation
                      ? advisorInformation.profileData.username
                      : "",
                    cxUsername: usernameCustomer,
                  })
                  .then((response) => {
                    const paymentStatus = response.data.paymentStatus;
                    const paymentId = response.data.paymentId;

                    if (paymentStatus === "PAID") paymentFailedStatus = true;
                    axios
                      .post(url.POST_QUERY_TO_ADVISOR, {
                        advisorUsername:
                          advisorInformation.profileData.username,
                        advisorName: advisorInformation.profileData.fullName,
                        platformServiceId: serviceInformation.platformServiceId,
                        cxEmail: formInformation.email,
                        cxName: formInformation.username,
                        phoneNumber: phone,
                        paymentStatus: paymentStatus,
                        paymentId: paymentId,
                        title: serviceInformation.title,
                        queryContent: formInformation.query,
                        price: serviceInformation.price,
                        usernameAdvisor: advisorInformation
                          ? advisorInformation.profileData.username
                          : "",
                        cxUsername: usernameCustomer,
                      })
                      .then((response) => {
                        toast.success("Query is sent to the advisor profile.", {
                          position: toast.POSITION.TOP_CENTER,
                        });

                        const bookingConfirmationData = {
                          title: serviceInformation.title,
                          calendar: "text_query",
                          advisorInformation: advisorInformation,
                        };
                        const { bookingId } = response.data;
                        navigate(
                          `/${serviceInformation.platformServiceId}/bookingConfirmation/bookingId=${bookingId}`,
                          {
                            state: bookingConfirmationData,
                          }
                        );
                      })
                      .catch((error) =>
                        toast.warning(error, {
                          position: toast.POSITION.TOP_RIGHT,
                        })
                      );
                  })
                  .catch((error) => {});
              },
              prefill: {
                name: formInformation.username,
                email: formInformation.email,
                contact: phone,
              },
              notes: {
                address: "",
              },
              theme: {
                color: "#3399cc",
              },
              modal: {
                ondismiss: async function () {
                  // first i need to check the if the payment initiated or not and the payment is succes or not.
                  if (!paymentFailedStatus) {
                    const orderId = response.data.orderId;
                    try {
                      const response = await axios.post(
                        url.POST_PAYMENT_PAY_RESPONSE,
                        {
                          orderId: orderId,
                          paymentId: null,
                          status: constant.CANCELED_BY_USER,
                          paymentStatus: constant.CANCELED_BY_USER,
                        }
                      );

                      if (response.status === 200) {
                        toast.warn("Your booking is not created....");
                      }
                    } catch (error) {
                      console.log("I am in error");
                      toast.error("Server error", {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                    }
                  }
                },
              },
            };

            const rzp1 = new Razorpay(options);

            rzp1.on("payment.failed", function (response) {
              paymentFailedStatus = true;
              toast.error("Payment Failed try again after sometime..!", {
                position: toast.POSITION.TOP_RIGHT,
              });

              const orderId = response.error.metadata.order_id;
              const paymentId = response.error.metadata.payment_id;

              axios
                .post(url.POST_PAYMENT_PAY_RESPONSE, {
                  orderId: orderId,
                  paymentId: paymentId,
                  status: constant.PAYMENT_RESPONSE_STATUS_FAIL,
                  paymentStatus: constant.PAYMENT_STATUS_FAILED,
                })
                .then((response) => {
                  console.log("Payment fail api");
                })
                .catch((error) => {
                  /// when it's network issue or something then the api should call
                  // console.log(error);
                  axios
                    .post(url.POST_PAYMENT_PAY_RESPONSE, {
                      orderId: orderId,
                      paymentId: paymentId,
                      status: constant.PAYMENT_RESPONSE_STATUS_ERROR,
                      paymentStatus: constant.PAYMENT_STATUS_FAILED,
                    })
                    .then((res) => console.log(res));
                });
            });

            rzp1.open();
          }
        });
    }
  };
  function handleSubmit() {
    let errors = validateAllFields();

    let keys = Object.keys(errors);
    let count = keys.reduce((acc, curr) => (errors[curr] ? acc + 1 : acc), 0);

    if (count === 0) {
      handlePayment();
    } else {
      setErrors((preValue) => (preValue != errors ? errors : preValue));
    }
  }
  function handleChange(event) {
    const { name, value } = event.target;

    setFormInformation((prevState) => {
      return { ...prevState, [name]: value };
    });
  }
  return (
    <div className="text-query">
      <div className="service-details text-center">
        <div className="service-body">
          <h5 className="service-details-title">
            {serviceInformation && serviceInformation.title}
          </h5>
          <div className="combine">
          <p
            className="description-about-question"
            dangerouslySetInnerHTML={{
              __html: serviceInformation && serviceInformation.description,
            }}
          ></p>
          <p className=" other-information">
          ₹ {serviceInformation && serviceInformation.price}
          </p>
          </div>
        </div>
      </div>
      <div className="text-query-container">
        <div className="text-query-service-information">
          <div className="container">
            <div className="form-group">
              <div className="username-field">
                <label className="form-label">Your Name</label>
                <input
                  type="username"
                  className="form-control"
                  name="username"
                  placeholder="Enter your name"
                  onChange={(event) => handleChange(event)}
                  value={formInformation.username}
                />
                {errors.username ? (
                  <span className="text-danger">{errors.username}</span>
                ) : (
                  ""
                )}
              </div>

              <div className="email-field">
                <label className="form-label">Enter your Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Enter your email here"
                  onChange={(event) => handleChange(event)}
                  value={formInformation.email}
                />
                {errors.email ? (
                  <span className="text-danger">{errors.email}</span>
                ) : (
                  ""
                )}
              </div>

              <div className="mb-3">
                <label className="form-label martop">Mobile number</label>
                <div className="phone-number">
                  <PhoneInput
                    country={"in"}
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                  />
                </div>
                {errors.phone ? (
                  <span className="text-danger">{errors.phone}</span>
                ) : (
                  ""
                )}
              </div>

              <div className="query-field">
                <label className="form-label">Enter your question:- </label>
                <textarea
                  className="form-control"
                  name="query"
                  placeholder="Ask your question here.."
                  type="text"
                  onChange={(event) => handleChange(event)}
                  value={formInformation.query}
                />
                {errors.query ? (
                  <span className="text-danger">{errors.query}</span>
                ) : (
                  ""
                )}
              </div>
              <div className="sumbit-button">
              <button className="btn btn-lg btn-primary" onClick={handleSubmit}>
                Confirm
              </button>
            </div>
            </div>

            
          </div>
        </div>
      </div>
    </div>
  );
}
