import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./home.css";
import gettingDetails from "../../../Utility/DataFetching/gettingDetails";
import constant from "../../../Utility/Constants/constant";

function Home() {
  let [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    const value = gettingDetails(constant.ABD_SESSION);
    const details = JSON.parse(value);
    setUserDetails((prevDetails) => {
      // Calculate the new state based on the previous state (prevDetails)
      const updatedDetails = { ...prevDetails, ...details };
      return updatedDetails;
    });

    return () => {
      // This code will run when the component unmounts.
      // You can perform cleanup or additional actions here.
    };
  }, []);
  return (
    <div className="homepage">
      <div className="header">
        <div className="header-title d-flex flex-row">
          <p className="text-start fs-1 fw-bold">
            Hi, {userDetails ? userDetails.username : ""}
          </p>
        </div>
      </div>
      <hr />

      <div className="main">
        <div className="row">
          <div className="col-4 fw-normal text-uppercase text-start">
            Checklist
          </div>
          <div className="col-8 fw-normal text-uppercase text-start">
            1/4 completed
          </div>
        </div>
        <div className="checklist border">
          <div className="row">
            {/* need to set the columns with the col-xs col-md and all means for the devices.
                need to set the description a little bolder */}
            <div className="col-2">
              <span className="fa-solid fa-circle-user text-center"></span>
            </div>
            <div className="col-4">
              <div className="title fw-bold text-start">
                <del>Account created</del>
              </div>

              <div className="description text-start text-muted">
                <small>Your page is live,see page</small>
              </div>
            </div>
            <div className="col-2">
              <span className="fa-solid fa-circle-check"></span>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-2">
              <span className="fa-solid fa-book"></span>
            </div>
            <div className="col-4">
              <div className="title fw-bold text-start">Add Services</div>
              <div className="description text-start text-muted">
                <small>Add services that people can book from</small>
              </div>
            </div>
            {/* make the border radius circle  */}
            <div className="col-2">
              <button className="btn btn-secondary btn-sm">+Add</button>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-2">
              <span className="fa-solid fa-calendar-days"></span>
            </div>
            <div className="col-4">
              <div className="title fw-bold text-start">
                Set your available time
              </div>
              <div className="description text-start text-muted">
                <small>Define ranges of time for seamless scheduling</small>
              </div>
            </div>
            <div className="col-2">
              <button className="btn btn-secondary btn-sm">+Add</button>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-2">
              <span className="fa-solid fa-share-nodes"></span>
            </div>
            <div className="col-4">
              <div className="title fw-bold text-start">Share profile</div>
              <div className="description text-start text-muted">
                <small>
                  Get your first booking,share your link and spread the word
                </small>
              </div>
            </div>
            <div className="col-2">
              <button
                className="btn btn-dark btn-sm"
                style={{ borderRadius: "5px" }}
              >
                Share
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
