import React, { useState, useEffect, useRef } from "react";
import "./Calls.css";
import url from "../../../Utility/URL/url";
import axios from "axios";
import { toast } from "react-toastify";
import CallsModal from "./CallsModal";
import Loader from "../../../Utility/loader/Loader";
import getUsernameFromSession from "../../../Utility/gettingInformation/getUsernameFromSession";
import authHeader from "../../../headerTokenUtility/auth.header";
import moment from "moment";
import { ReactComponent as PageNotFound } from "../../../Utility/ErrorPages/PageNotFound.svg";
function Calls() {
  const firstRender = useRef(true);
  const [selectedFilterCalls, setSelectedFilterCalls] = useState("Pending");
  const [openModal, setOpenModal] = useState(false);
  const [pendingCalls, setPendingCalls] = useState([]);
  const [completedCalls, setCompletedCalls] = useState([]);
  const [selectedCall, setSelectedCall] = useState(null);
  const [loading, setLoading] = useState(true);
  const [availability, setAvailability] = useState();
  const username = getUsernameFromSession();

  async function getAllCalls() {
    try {
      if (selectedFilterCalls === "Pending") {
        const pendingResponse = await axios.get(
          url.GET_PENDING_CALLS + username,
          {
            headers: authHeader(),
          }
        );
        if (pendingResponse.status === 200) {
          setPendingCalls(() => pendingResponse.data);
        }
      }

      if (selectedFilterCalls === "Completed") {
        const completedResponse = await axios.get(
          url.GET_COMPLETED_CALLS + username,
          {
            headers: authHeader(),
          }
        );
        if (completedResponse.status === 200) {
          setCompletedCalls(() => completedResponse.data);
        }
      }

      setLoading(() => false);
    } catch (error) {
      toast.warning("Technical Issue while fetching the data", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  async function getAvailabilityHere() {
    try {

      let response = await axios.get(url.GET_AVAILABILITY_DETAILS + username, {
        headers: authHeader(),
      });

      setAvailability((currAvailability) =>
        currAvailability !== response.data ? response.data : currAvailability
      );
    } catch (error) {
      toast.warning("Technical Issue while fetching the data", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  useEffect(() => {
    getAllCalls();
    getAvailabilityHere();

    return () => {
      //pending request will be canceled here/
    };
  }, []);
  function handleRescheduleApi(callDetails, details) {
    const { selectedTime, selectedDate } = details;

    const data = {
      ...callDetails,
      bookingTime: selectedTime,
      bookingDate: moment(selectedDate).format("YYYY-MM-DD"),
    };
    toast
      .promise(rescheduleCall(data), {
        pending: "Rescheduling Call",
        success: "Rescheduled Call successfully!",
        error: "Failed to Rescheduled the Call. Please try again later...",
      })
      .then((response) => {
        if (response.status === 200) {
          handleCloseModal();
          setLoading(() => true);
          getAllCalls();
        }
      });
  }
  function rescheduleCall(data) {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await axios.post(url.RESCHEDULE_CALL, data, {
          headers: authHeader(),
        });

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }
  function handleMarkAsComplete(callDetails) {
    axios
      .post(url.POST_MARK_AS_COMPLETED + callDetails.bookingId, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Call completed successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setSelectedCall(null);
          setOpenModal(false);
          setLoading(() => true);
          getAllCalls();
        }
      })
      .catch((error) => {
        toast.warning("Completion is not done yet something weird happend!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }
  function handleOpenModal(call) {
    setSelectedCall(() => call);
    setOpenModal(() => true);
  }
  function handleCloseModal() {
    setSelectedCall(null);
    setOpenModal(false);
  }
  function getCallsListUI() {
    let calls;

    if (selectedFilterCalls === "Pending") {
      calls = pendingCalls;
    } else {
      calls = completedCalls;
    }

    return calls.length === 0 ? (
      <div className="container list-is-empty text-center">
        <div className="Image mt-5 pt-5">
          <PageNotFound height={"300px"} />
        </div>
        <h1 className="display-5 text-muted">Looks like nothing is here!!!</h1>
      </div>
    ) : (
      calls.map((call, index) => {
        return (
          <div
            className="col-lg-6"
            style={{
              paddingLeft: "10px",
              paddingRight: "100px",
            }}
            key={index}
          >
            <div className="calls-card">
              <div className="row calls-header">
                <div className="col-lg-7">
                  {formatDate(call.bookingDate)}{" "}
                  {formatBookingTime(call.bookingTime, call.callDuration)}
                </div>
                <div className="col-lg-5 text-end">
                  {selectedFilterCalls === "Pending" ? (
                    call.bookingState === "NO_SHOW" ? (
                      <span className="badge rounded-pill text-bg-danger">
                        No Show
                      </span>
                    ) : (
                      <span className="badge rounded-pill text-bg-danger">
                        Pending
                      </span>
                    )
                  ) : call.bookingState === "BOOKING_CANCELED" ? (
                    <span className="badge rounded-pill text-bg-danger">
                      Declined
                    </span>
                  ) : (
                    <span className="badge rounded-pill text-bg-success">
                      Success
                    </span>
                  )}
                </div>
              </div>
              <div className="row calls-content">
                <div className="col-lg-12">
                  <div className="fs-3">{call.cxName}</div>
                  <div className="title">{call.title}()</div>
                </div>
              </div>
              <div className="row calls-footer">
                <div className="col-lg-6">
                  <button
                    className="btn btn-sm btn-dark"
                    onClick={(event) => {
                      handleOpenModal(call);
                    }}
                  >
                    Call Details
                  </button>
                </div>
                {!call.isCompleted && (
                  <div className="col-lg-6 text-end">
                    <button className="btn btn-sm btn-success">
                      Join call
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })
    );
  }
  async function handleDeclineCall(bookingId, reason) {
    try {
      await axios.delete(url.DELETE_CALL_SERVICE + bookingId, {
        headers: authHeader(),
      });

      toast.error("Call Declined!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      handleCloseModal();
      getAllCalls();
      getAvailabilityHere();
    } catch (error) {
      toast.warning("Call is not declined.. Please try again later !");
    }
  }
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    getAllCalls();
  }, [selectedFilterCalls]);
  function formatBookingTime(bookingTime, callDuration) {
    const [time, period] = bookingTime.split(" ");
    const [hours, minutes] = time.split(":");

    let formattedHours = Number(hours);
    const formattedMinutes = Number(minutes);

    if (period.toLowerCase() === "pm" && formattedHours < 12) {
      formattedHours += 12;
    }

    const start = new Date();
    start.setHours(formattedHours);
    start.setMinutes(formattedMinutes);

    const end = new Date(start.getTime() + callDuration * 60000);

    const formattedStartTime = formatTime(start.getHours(), start.getMinutes());
    const formattedEndTime = formatTime(end.getHours(), end.getMinutes());
    const formattedBookingTime = `${formattedStartTime} - ${formattedEndTime}`;

    return formattedBookingTime;
  }

  function formatTime(hours, minutes) {
    let formattedHours = hours % 12;
    if (formattedHours === 0) {
      formattedHours = 12;
    }

    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedHoursWithLeadingZero =
      formattedHours < 10 ? `0${formattedHours}` : `${formattedHours}`;
    const period = hours < 12 ? "am" : "pm";

    return `${formattedHoursWithLeadingZero}:${formattedMinutes} ${period}`;
  }

  function formatDate(dateString) {
    // Create a new Date object using the input dateString
    const date = new Date(dateString);

    // Define the day names and month names
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get the day, date, and month from the date object
    const dayName = days[date.getDay()];
    const dateNumber = date.getDate();
    const monthName = months[date.getMonth()];

    // Construct the formatted date string
    const formattedDate = `${dayName}, ${dateNumber} ${monthName}`;

    // Return the formatted date
    return formattedDate;
  }

  return (
    <div className="calls">
      <div className="header">
        <div className="header-title d-flex flex-row">
          <p className="text-start fs-1 fw-bold">Calls</p>
        </div>
      </div>
      <hr />
      <div className="main">
        {loading ? (
          <Loader />
        ) : (
          <div className="bookings">
            <div className="row">
              <div className="col-lg-12">
                <div className="tab-container">
                  <div className="tab-navigation">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            selectedFilterCalls === "Pending" ? "active" : ""
                          }`}
                          onClick={() => setSelectedFilterCalls("Pending")}
                          style={{ cursor: "pointer" }}
                        >
                          Pending
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            selectedFilterCalls === "Completed" ? "active" : ""
                          }`}
                          onClick={() => setSelectedFilterCalls("Completed")}
                          style={{ cursor: "pointer" }}
                        >
                          Completed
                        </a>
                      </li>
                    </ul>
                  </div>

                  {pendingCalls && completedCalls ? (
                    <div className="tab-content-holder">
                      <div className="tab-content">
                        <div className="row calls-main">
                          {/*map loop here for calls card */}
                          {getCallsListUI()}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {openModal && selectedCall !== null && (
          <CallsModal
            callDetails={selectedCall}
            closeModal={handleCloseModal}
            dateString={formatDate(selectedCall.bookingDate)}
            bookingTime={formatBookingTime(
              selectedCall.bookingTime,
              selectedCall.callDuration
            )}
            markAsComplete={handleMarkAsComplete}
            availability={availability}
            declineCall={handleDeclineCall}
            reschedule={handleRescheduleApi}
          />
        )}
      </div>
    </div>
  );
}

export default Calls;
