import React, { useEffect, useState } from "react";
import "./HomePage.css";
import homeBanner1on1Call from "../../Utility/images/homepageImage/1on1Call.png";
import homepageBanner from "../../Utility/images/homepageImage/call_management_banner.png";
import homeBannermonitizeTime from "../../Utility/images/homepageImage/monetizeYourTime.png";
import defaultProfilePic from "../../Utility/images/profilepic.jpg";
import HowItWorkVideo from "../../Utility/images/homepageImage/Howitworks-FINAL.mp4"
import axios from "axios";
import Carousel from 'react-bootstrap/Carousel';
import '@fortawesome/fontawesome-free/css/all.min.css';
import constant from "../../Utility/Constants/constant.js";
import HeaderSection from "../header/index.jsx";
import Footer from "../footer/index.jsx";
import Categories from "./Categories.js";
import IntegrationImg from "../../Utility/images/homepageImage/integrationImg.gif"


const urls = require("../../Utility/URL/url.js");


function HomePage() {

  const requestBody = {}
  const headers = {};

  const [activeFeature, setActiveFeature] = useState(constant.FEATURE_DATA[0]);
  const categories = Object.keys(Categories);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [email, setEmail] = useState('');

  function returnProfileImgUrl(params) {
    const img = require(`../../Utility/images/${params}`);
    return img;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the email submission logic here
    console.log(`Email Submitted: ${email}`);
  };

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        headers['X-Forwarded-For'] = data.ip;
        axios
          .post(urls.POST_USER_ON_LANDINGPAGE, requestBody, { headers })
          .then((res) => {
            if (res.status === 200) {
              const value = res.data;
              // dataStored('dashboard', value);
            }
          })
          .catch(error => console.log(error))
      })
      .catch(error => console.log(error))
  }, [])
  return (
    <React.Fragment>
      <HeaderSection />

      <section id="hero" className="d-flex align-items-center justify-content-center">
        <div className="container" id="dashboard">
          <Carousel fade>
            <Carousel.Item>
              <div className="row">
                <div
                  className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-2 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="headingDashboard">Transform your <br /><b>time into earnings</b></div>
                  <div className="subheadingDashboard">Schedule personalized video calls with your <br />audience and turn your skills into income.</div>
                  <div className="d-flex justify-content-lg-start justify-content-center"> <a href="/signup" className="loginBtn ">Get Started &nbsp;	<i className='fas text-white'>&#xf061;</i></a></div>
                </div>

                <div
                  className="col-lg-6 order-1 order-lg-2 hero-img d-flex justify-content-center"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  <img
                    src={homeBannermonitizeTime}
                    className="dashboardImage elementAnimation"
                    alt="bannerImg"
                  />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="row">
                <div
                  className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-2 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="headingDashboard">Monitize your <b>expertise <br />with 1:1 calls</b></div>
                  <div className="subheadingDashboard">Interact directly with your followers <br />and get paid for your time.</div>
                  <div className="d-flex justify-content-lg-start justify-content-center"> <a href="/signup" className="loginBtn ">Claim your free profile &nbsp;	<i className='fas text-white'>&#xf061;</i></a></div>
                </div>

                <div
                  className="col-lg-6 order-1 order-lg-2 hero-img d-flex justify-content-center"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  <img
                    src={homeBanner1on1Call}
                    className="dashboardImage elementAnimation"
                    alt="bannerImg"
                  />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="row">
                <div
                  className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-2 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="headingDashboard">Connect, <b>Share, <br />Earn</b></div>
                  <div className="subheadingDashboard">Your time is valuable—start earning <br />by sharing your knowledge.</div>
                  <div className="d-flex justify-content-lg-start justify-content-center"> <a href="/signup" className="loginBtn ">Join Now &nbsp;	<i className='fas text-white'>&#xf061;</i></a></div>
                </div>

                <div
                  className="col-lg-6 order-1 order-lg-2 hero-img d-flex justify-content-center"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  <img
                    src={homepageBanner}
                    className="dashboardImage elementAnimation"
                    alt="bannerImg"
                  />
                </div>
              </div>
            </Carousel.Item>
          </Carousel >
        </div >
      </section >

      {/* <!-- ======= Feature Section ======= --> */}
      <section id="feature" className="section services section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Features</h2>
          </div>
          <div className="row d-md-flex flex-column-reverse flex-lg-row">
            <div className="featuresSubHeading">Here are some key features that distinguish Advising Buddy from other applications.</div>
          </div>

          <FeatureHeader
            features={constant.FEATURE_DATA.map(f => f.feature)}
            activeTheme={activeFeature.color}
            activeFeature={activeFeature.feature}
            onFeatureClick={(featureName) => {
              const selectedFeature = constant.FEATURE_DATA.find(f => f.feature === featureName);
              setActiveFeature(selectedFeature);
            }}
          />
          <FeatureSection content={activeFeature.content} icons={constant.FEATURE_ICON_MAPPING} activeTheme={activeFeature.color} />
        </div>
      </section>
      {/* <!-- End Feature Section --> */}

      <section id="howItWorkContent">
        <div>
        <h1 className="heading pt-5 mb-0">How It Works</h1>
          <video className="howItWorkVideo" autoPlay loop muted playsInline>
            <source src={HowItWorkVideo} type="video/mp4"alt="howItWork"  />
          </video>
        </div>
      
      </section>
      {/* hero section */}
      <section id="myhero">
        <div className="container py-5">
          <h1 className="heading">Meet Our Hero Advisors</h1>

          {/* Category Selection */}
          <div className="categoryContainer">
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`categoryButton ${selectedCategory === category ? "activeButton" : ''}`}
              >
                {category.replace(/([A-Z])/g, ' $1').trim()}
              </button>
            ))}
          </div>

          {/* Advisors Grid */}
          {Categories[selectedCategory] ? (
            <div className="gridContainer">
              {Categories[selectedCategory].map((advisor) => (
                <div key={advisor.username} className="card">
                  <div className="imagePlaceholder">
                    <img
                      src={advisor.profile ? returnProfileImgUrl(advisor.profile) : defaultProfilePic}
                      alt="advisor-img"
                    />
                  </div>
                  <h3 className="advisorName">{advisor.name}</h3>
                  <p className="advisorInfo">Rating: {advisor.rating}/5</p>
                </div>
              ))}
            </div>
          ) : (
            <p className="noAdvisors">No advisors available for this category.</p>
          )}
        </div>
      </section>
      {/* End hero section*/}


      {/**Integration Section */}

      <section className="integrations-container">
        <h1 className="integrations-title">Integrations</h1>

        <div className="integrations-content">
          <div className="integrations-text">
            <h2>Effortlessly Integrated with Your Favorite Tools</h2>
            <p>
            Our platform brings together all the apps you rely on. From Google Meet and Calendars to payment gateways like Razorpay and Stripe, enjoy seamless access to all your tools right here. Manage your schedules, payments, and more—all in one place, making your experience smoother and more efficient.
            </p>
            <button className="connect-button">Start your free account →</button>
            {/* <p className="beta-note">
              Dropbox Dash is currently in beta and only available to users in the US in English only.
            </p> */}
          </div>

          <div className="integrations-icons">
            <img src={IntegrationImg} alt="Main Hub" className="hub-icon" />

          </div>
        </div>
      </section>

      <section className="advisor-section">
        <h1 className="advisor-title">Become a Advisor</h1>
        <div className="advisor-form-wrapper">
          <form className="advisor-form" onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit" className="advisor-button">
              Get start for free
            </button>
          </form>
        </div>
      </section>

      <Footer />


    </React.Fragment >
  );
}

const FeatureHeader = ({ features, activeFeature, onFeatureClick, activeTheme }) => {
  return (
    <div className="feature-header">
      <nav className="nav">
        <ul className="nav-tabs">
          {features.map((feature, index) => (
            <li
              key={index}
              className={activeFeature === feature ? (activeTheme === 'red' ? 'active-red' : activeTheme === 'blue' ? 'active-blue' : activeTheme === 'yellow' ? 'active-yellow' : activeTheme === 'green' ? 'active-green' : '') : ''}
              onClick={() => onFeatureClick(feature)}
            >
              {feature}
            </li>
          ))}
        </ul>
      </nav>
    </div >
  );
};

const FeatureSection = ({ content, icons, activeTheme }) => {
  return (
    <div className="feature-section-wrapper">
      <div className="cta-box">
        <h3>Grow faster with pro’s help</h3>
        <p>Experience top-notch features with our services</p>
        <a href="/signup"><button className={`cta-button`} >Get Started</button></a>
      </div>
      <div className="feature-section">
        <div className="features">
          {content.map((item, index) => (
            <div className="feature-card d-flex " key={index}>
              <i
                className={icons[item.title]}
                style={{ fontSize: "24px", color: "#5a67d8", marginRight: "20px" }}></i>
              <div className="text-left">
                <h4>{item.title}</h4>
                <p>{item.discription}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div >

  );
};


export default HomePage;

