const Categories = {
  SoftwareEngineer: [
    {
      username: "ritik",
      name: "Ritik Saini",
      rating: 5,
      profile: ""
    },
    {
      username: "aman1",
      name: "Aman Goyal",
      rating: 5,
      profile: "HealthyHamesha.png"
    },
  ],
  CryptoExperts: [
    {
      username: "sandeep",
      name: "Sandeep Bahuguna",
      rating: 5,
      profile: "sandeep.png"
    },
    {
      username: "aditya",
      name: "Aditya Singh",
      rating: 5,
      profile: "aditya.png"
    },
  ],
  FinancialExperts: [
    {
      username: "LabourLawAdvisor",
      name: "Labour Law Advisor",
      rating: 5,
      profile: "HealthyHamesha.png"
    },
    {
      username: "CARachanaRanade",
      name: "CA Rachana Ranade",
      rating: 5,
      profile: "CARachanaRanade.jpg"
    },
  ],
  Influencers: [
    {
      username: "AnkurWarikoo",
      name: "Ankur Warikoo",
      rating: 5,
      profile: "AnkurWarikoo.jpg"
    },
    {
      username: "RanveerAllahbadia",
      name: "Ranveer Allahbadia",
      rating: 5,
      profile: "RanveerAllahbadia.jpg"
    },
  ],
  LegalExperts: [
    {
      username: "VidhanKaGyan",
      name: "Naresh Kudal",
      rating: 5,
      profile: "VidhanKaGyan.png"
    },
    {
      username: "VishnuJain",
      name: "Vishnu Jain",
      rating: 5,
      profile: "vishnu-jain.png"
    },
  ],
  HealthExperts: [
    {
      username: "HealthyHamesha",
      name: "Healthy Hamesha",
      rating: 5,
      profile: "HealthyHamesha.png"
    },
    {
      username: "VKMishra",
      name: "Dr. V.K. Mishra",
      rating: 5,
      profile: "VKMishra.jpg"
    },
  ],
};

export default Categories;
