import axios from "axios";
import React, { useEffect, useState } from "react";
import url from "../../../Utility/URL/url";
import { useParams } from "react-router-dom";
import ServiceInformation from "../ServiceInformation";
import Loader from "../../../Utility/loader/Loader";
import FourZeroFour from "../../../Utility/ErrorPages/FourZeroFour/FourZeroFour";

function SearchServiceFromDB() {
  const { username, id } = useParams();
  const [dbInformation, setDbInformation] = useState();
  let advisorInfo = {};
  let serviceInformation = {};
  const [loader, setLoader] = useState(true);
  const [fourZeroFour, setFourZeroFour] = useState(false);

  function getAdvisorDetails() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          url.GET_SERVICE_BY_USERNAME_AND_PLATFORMSERVICEID +
            id +
            `?username=${username}`
        )
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
  useEffect(() => {
    //lexical declartion must be intialized before used.
    const advisorDetails = async () => {
      try {
        const details = await getAdvisorDetails();
        const { data } = details;
        setDbInformation(() => data);
        setLoader(() => false);

        
        
      } catch (error) {
   
        if (error) {
          setFourZeroFour(() => true);
          setLoader(() => false);
        }
      }
    };

    advisorDetails();
  }, []);

  if (dbInformation) {
    const { userReviews, serviceData, availability, profileData } =
      dbInformation;

    advisorInfo.userReviews = userReviews;
    advisorInfo.availability = availability;
    advisorInfo.profileData = profileData;
    serviceInformation = serviceData;
  }
  return loader ? (
    <Loader />
  ) : fourZeroFour ? (
    <FourZeroFour />
  ) : (
    <ServiceInformation
      advisorInfo={advisorInfo}
      serviceInformation={serviceInformation}
    />
  );
}
export default SearchServiceFromDB;
