import React, { useEffect, useState } from "react";
import "./SideBar.css";
import { NavLink, Outlet } from "react-router-dom";

function SideBar() {
  const [menus, setMenus] = useState([
    { text: "Home", icon: "fa-solid fa-house" },
    { text: "Calls", icon: "fa-solid fa-phone" },
    { text: "Queries", icon: "fa-solid fa-clipboard-question" },
    { text: "Rewards", icon: "fa-solid fa-gift" },
    { text: "Profile", icon: "fa-solid fa-user" },
    { text: "Services", icon: "fa-solid fa-book" },
    { text: "Availability", icon: "fa-solid fa-calendar-days" },
    { text: "Payments", icon: "fa-solid fa-wallet" },
    { text: "Settings", icon: "fa-solid fa-gear" },
  ]);
  const [selectedMenu, setSelectedMenu] = useState("Home");

  
  return (
<React.Fragment>
    <nav>
      <div className="logo">
        <i className="bx bx-menu menu-icon"></i>
        <span className="logo-name">Advising Buddy</span>
      </div>

      <div className="sidebar">
        <div className="logo">
          <i className="bx bx-menu menu-icon"></i>
          <span className="logo-name">Advising Buddy</span>
        </div>

        <div className="sidebar-content">
          <ul className="lists">
          {menus.map((menu, index) => (
            <li className="list" key={index}>
              <NavLink to={`/dashboard/${menu.text}`} className="nav-link">
                <i className={`bx ${menu.icon} icon`}></i>
                <span className="link">{menu.text}</span>
              </NavLink>
            </li>
          ))}
            
          </ul>

          {/* <div className="bottom-cotent">
            <li className="list">
              <a href="#" className="nav-link">
                <i className="bx bx-cog icon"></i>
                <span className="link">Settings</span>
              </a>
            </li>
            <li className="list">
              <a href="#" className="nav-link">
                <i className="bx bx-log-out icon"></i>
                <span className="link">Logout</span>
              </a>
            </li>
          </div> */}
        </div>
      </div>
    </nav>
    
      <div className="right-panel">
        <Outlet />
      </div>
    </React.Fragment>
  );
}

export default SideBar;
