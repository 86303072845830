const timezones = [
  {
    label: "(UTC +10:00) Australia,Queensland (AEST)",
    value: "Australia/Brisbane",
  },
  {
    label: "(UTC +11:00) Australia,Sydney (AEST)",
    value: "Australia/Sydney",
  },
  {
    label: "(UTC +10:30) Australia,Adelaide (ACDT)",
    value: "Australia/Adelaide",
  },
  {
    label: "(UTC +09:30) Australia,Darwin (ACST)",
    value: "Australia/Darwin",
  },
  { label: "(UTC +08:00) Australia,Perth (AWST)", value: "Australia/Perth" },
  { label: "(UTC +09:00) Japan (JST)", value: "Asia/Tokyo" },
  { label: "(UTC +09:00) South Korea (KST)", value: "Asia/Pyongyang" },
  { label: "(UTC +09:00) North Korea (KST)", value: "Asia/Seoul" },
  { label: "(UTC +08:00) China,Beijing (CST)", value: "Asia/Shanghai" },
  { label: "(UTC +08:00) Philippines (PST)", value: "Asia/Manila" },
  { label: "(UTC +08:00) Singapore (SGT)", value: "Asia/Singapore" },
  { label: "(UTC +08:00) Malasiya (MYT)", value: "Asia/Kuala_Lumpur" },
  { label: "(UTC +08:00) Tiwan (TW)", value: "Asia/Taipei" },
  { label: "(UTC +08:00) China, HongKong (HKT)", value: "Asia/Hong_Kong" },
  { label: "(UTC +07:00) Thailand (ICT)", value: "Asia/Bangkok" },
  { label: "(UTC +07:00) Vietnam (VNM)", value: "Asia/Ho_Chi_Minh" },
  { label: "(UTC +07:00) Cambodia (KHM)", value: "Asia/Phnom_Penh" },
  { label: "(UTC +07:00) Indonesia,Bali (WITA)", value: "Asia/Makassar" },
  { label: "(UTC +07:00) Indonesia,Jayapura (WITA)", value: "Asia/Jayapura" },
  { label: "(UTC -03:00) Brazil (BRT)", value: "Sao Paulo" },
  {
    label: "(UTC -03:00) Brazil, Salvador (BRST)",
    value: "America/El_Salvador",
  },
  {
    label: "(UTC -04:00) Brazil, Mato Grosso (AMT)",
    value: "America/Campo_Grande",
  },
  {
    label: "(UTC -05:00) Brazil, Rio_Branco (ACT)",
    value: "America/Rio_Branco",
  },
  { label: "(UTC -06:00) Canada, Alberta (MDT)", value: "America/Edmonton" },
  { label: "(UTC -06:00) Canada, Calgary (MDT)", value: "America/Edmonton" },
  {
    label: "(UTC -07:00) Canada, Vancouver (PDT)",
    value: "America/Vancouver",
  },
  { label: "(UTC -04:00) Canada, Ontario (EDT)", value: "America/Toronto" },
  { label: "(UTC -04:00) Canada, Toronto (EDT)", value: "America/Toronto" },
  {
    label: "(UTC -04:00) Canada, Quebec/Montreal (EDT)",
    value: "America/Toronto",
  },
  { label: "(UTC -07:00) Canada, Yukon (PDT)", value: "America/Whitehorse" },
  {
    label: "(UTC -06:00) Canada, Saskatchewan (CST)",
    value: "America/Regina",
  },
  { label: "(UTC -05:00) Manitoba (CDT)", value: "America/Winnipeg" },
  { label: "(UTC -05:00) Coral Harbour (EST)", value: "America/Atikokan" },
  {
    label: "(UTC -02:30) Canada, St. John's (NDT)",
    value: "America/St_Johns",
  },
  { label: "(UTC +04:30) Afganistan, Kabul (AFT)", value: "Asia/Kabul" },
  {
    label: "(UTC -03:00) Argentina (ARG)",
    value: "America/Argentina/Buenos_Aires",
  },
  { label: "(UTC +01:00) Austria (CET)", value: "Europe/Vienna" },
  { label: "(UTC +06:00) Bangladesh (BST)", value: "Asia/Dhaka" },
  { label: "(UTC +06:00) Bhutan (BTT)", value: "Asia/Thimphu" },
  { label: "(UTC +07:00) Cambodia (ICT)", value: "Asia/Phnom_Penh" },
  { label: "(UTC +01:00) Czech Republic (CET)", value: "Europe/Prague" },
  { label: "(UTC +02:00) Finland (EET)", value: "Europe/Helsinki" },
  { label: "(UTC +01:00) France, Paris (CET)", value: "Europe/Paris" },
  { label: "(UTC +01:00) Germany (CET)", value: "Europe/Berlin" },
  { label: "(UTC +05:30) India (IST)", value: "Asia/Kolkata" },
  { label: "(UTC +01:00) Italy (CET)", value: "Europe/Rome" },
  { label: "(UTC +03:00) Kenya (EAT)", value: "Africa/Nairobi" },
  { label: "(UTC +05:00) Maldives (MVT)", value: "Indian/Maldives" },
  { label: "(UTC +04:00) Mauritius (MUT)", value: "Indian/Mauritius" },
  { label: "(UTC -06:00) Mexico (CDMX)", value: "America/Mexico_City" },
  { label: "(UTC +01:00) Monaco (CET)", value: "Europe/Monaco" },
  { label: "(UTC +06:30) Myanmar (MMT)", value: "Asia/Yangon" },
  { label: "(UTC +05:45) Nepal (NTP)", value: "Asia/Kathmandu" },
  { label: "(UTC +01:00) Netherlands (CET)", value: "Europe/Amsterdam" },
  { label: "(UTC +01:00) Nigeria (WAT)", value: "Africa/Lagos" },
  { label: "(UTC +01:00) Norway (CET)", value: "Europe/Oslo" },
  { label: "(UTC +05:00) Pakistan (PKT)", value: "Asia/Karachi" },
  { label: "(UTC +01:00) Poland (CET)", value: "Europe/Warsaw" },
  { label: "(UTC +00:00) Portugal (WET)", value: "Europe/Lisbon" },
  { label: "(UTC +01:00) Portugal (AZOT)", value: "Atlantic/Azores" },
  { label: "(UTC +03:00) Qatar (AST)", value: "Asia/Qatar" },
  { label: "(UTC +02:00) Romania (EET)", value: "Europe/Bucharest" },
  { label: "(UTC +04:00) Russia, Samara (SAMT)", value: "Europe/Samara" },
  { label: "(UTC +03:00) Russia, Moscow (MSK)", value: "Europe/Moscow" },
  { label: "(UTC +09:00) South Korea, Seoul (KST)", value: "Asia/Seoul" },
  { label: "(UTC +01:00) Spain (CET)", value: "Europe/Madrid" },
  { label: "(UTC +05:30) Sri Lanka (SLT)", value: "Asia/Colombo" },
  { label: "(UTC +01:00) Sweden (CET)", value: "Europe/Stockholm" },
  { label: "(UTC +01:00) Switzerland (CET)", value: "Europe/Zurich" },
  { label: "(UTC +03:00) Syria (EEST)", value: "Asia/Damascus" },
  { label: "(UTC +03:00) Turkey (TRT)", value: "Europe/Istanbul" },
  { label: "(UTC +02:00) Ukraine (EET)", value: "Europe/Kyiv" },
  { label: "(UTC +04:00) United Arab Emirates (GST)", value: "Asia/Dubai" },
  { label: "(UTC +00:00) United Kingdom (GMT)", value: "Europe/London" },
  { label: "(UTC +02:00) Zimbabwe (CAT)", value: "Africa/Harare" },
  {
    label: "(UTC -04:00) America, New York (EDT)",
    value: "America/New_York",
  },
  { label: "(UTC -05:00) America, Chicago (CDT)", value: "America/Chicago" },
  { label: "(UTC -06:00) America, Denver (MDT)", value: "America/Denver" },
  { label: "(UTC -07:00) America, Phoenix (MST)", value: "America/Phoenix" },
  {
    label: "(UTC -07:00) America, Los Angeles (PDT)",
    value: "America/Los_Angeles",
  },
  {
    label: "(UTC -08:00) America, Anchorage (AKDT)",
    value: "America/Anchorage",
  },
  { label: "(UTC -09:00) America, Adak (HADT)", value: "America/Adak" },
  {
    label: "(UTC -10:00) America, Honolulu (HAST)",
    value: "Pacific/Honolulu",
  },
];

module.exports = timezones;
