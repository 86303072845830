import constant from "../Constants/constant";
import gettingDetails from "../DataFetching/gettingDetails";

export default function getUsernameFromSession() {
  const value = gettingDetails(constant.ABD_SESSION);

  const object = JSON.parse(value);

  if (object) {
    return object.username ? object.username : "";
  }
}
