import React from 'react'

import './privacyPolicy.css'
import HeaderSection from '../header'
import Footer from '../footer'

const PrivacyPolicy = () => {
    return (
        <>
            <HeaderSection />
            <section className="contact-section">
                <div className='mx-lg-5 w-100 policy-section'>
                    <h2>Privacy Policy</h2>

                    <h4><b>What do we do with your information?</b></h4>
                    <p>
                        When you schedule a meeting or consultation on AdvisingBuddy.com, as part of the interaction process, we collect personal information such as your name, email address, phone number, and payment details.
                    </p>
                    <p>
                        When you browse our site, we automatically receive your computer’s internet protocol (IP) address, which helps us understand your browser and operating system for better functionality and user experience.
                    </p>
                    <p>
                        <b>Email marketing (if applicable):</b> With your permission, we may send you emails about AdvisingBuddy.com, new features, services, or updates.
                    </p>

                    <h4><b>Consent</b></h4>
                    <h5><b>How do you get my consent?</b></h5>
                    <p>
                        When you provide personal information to schedule a session, complete a transaction, or verify your payment details, we imply your consent to our collecting and using it for that specific purpose only.
                    </p>
                    <p>
                        If we ask for your personal information for any other reason, such as marketing, we will either ask you directly for your expressed consent or provide you with an opportunity to decline.
                    </p>

                    <h5><b>How do I withdraw my consent?</b></h5>
                    <p>
                        If after opting in, you change your mind, you may withdraw your consent for us to contact you, or for the continued collection or use of your information, at any time by contacting us at <b>support@advisingbuddy.com</b>.
                    </p>

                    <h4><b>Disclosure</b></h4>
                    <p>
                        We may disclose your personal information if required by law to do so or if you violate our Terms of Service.
                    </p>

                    <h4><b>Payment</b></h4>
                    <p>
                        We use Razorpay to process payments. Neither we nor Razorpay stores your card data on their servers. Your payment information is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) when processed. Your transaction data is used only as long as is necessary to complete your purchase and is not stored afterward.
                    </p>
                    <p>
                        Razorpay adheres to PCI-DSS standards as managed by the PCI Security Standards Council, ensuring secure handling of your payment information.
                    </p>
                    <p>
                        For more insight, you can read the terms and conditions of Razorpay at <a className='text-white' href="https://razorpay.com" target="_blank">https://razorpay.com</a>.
                    </p>

                    <h4><b>Third-party services</b></h4>
                    <p>
                        The third-party providers used by us only collect, use, and disclose your information as necessary to enable them to perform the services they provide to us.
                    </p>
                    <p>
                        However, certain third-party providers, such as payment gateways and other processors, have their own privacy policies regarding the information required for your transactions. For these providers, we recommend that you review their privacy policies to understand how your personal data is handled.
                    </p>
                    <p>
                        Once you leave AdvisingBuddy.com or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our Terms of Service.
                    </p>

                    <h4><b>Links</b></h4>
                    <p>
                        When you click on links on our site, they may direct you away from AdvisingBuddy.com. We are not responsible for the privacy practices of other websites and encourage you to read their privacy statements.
                    </p>

                    <h4><b>Security</b></h4>
                    <p>
                        We take reasonable precautions and follow industry best practices to ensure your personal information is not inappropriately lost, misused, accessed, disclosed, altered, or destroyed.
                    </p>

                    <h4><b>Cookies</b></h4>
                    <p>
                        We use cookies to maintain your session on AdvisingBuddy.com. These cookies do not collect personal information to identify you on other websites.
                    </p>

                    <h4><b>Age of Consent</b></h4>
                    <p>
                        By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority and have given your consent to allow any of your minor dependents to use this site.
                    </p>

                    <h4><b>Changes to this Privacy Policy</b></h4>
                    <p>
                        We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon posting on the website. If we make significant changes, we will notify you so that you understand what information we collect, how we use it, and under what circumstances, if any, we disclose it.
                    </p>
                    <p>
                        If AdvisingBuddy.com is acquired or merged with another company, your information may be transferred to the new owners to continue providing services to you.
                    </p>

                    <h4><b>Questions and Contact Information</b></h4>
                    <p>
                        If you would like to access, correct, amend, or delete any personal information we have about you, register a complaint, or simply want more information, contact us by email at <b><a className='text-white' href='mailTo:support@advisingbuddy.com'>support@advisingbuddy.com</a></b>.
                    </p>

                </div>
            </section >

            <Footer />
        </>
    )
}

export default PrivacyPolicy