import React, { useEffect, useState } from "react";
import "./Availability.css";
import TimeSelector from "./TimeSelector";
import url from "../../../Utility/URL/url";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../../Utility/loader/Loader";
import Configuration from "./Configuration";
import authHeader from "../../../headerTokenUtility/auth.header";
import getUsernameFromSession from "../../../Utility/gettingInformation/getUsernameFromSession";

function Availability() {
  const headerButtons = ["Schedule", "Calendar"];
  const [selectedHeader, setSelectedHeader] = useState("Schedule");
  const [availabilityDetails, setAvailabilityDetails] = useState();
  const [loading, setLoading] = useState(true);
  const username = getUsernameFromSession();

  const handleChangeButton = (value) => {
    setSelectedHeader((currValue) => (currValue !== value ? value : currValue));
  };

  useEffect(() => {
    getAvailabilityDetails();

    return () => {
      //component unmount cancel any pending request.
    };
  }, []);
  async function getAvailabilityDetails() {
    try {
      if (username) {
        const response = await axios.get(
          url.GET_AVAILABILITY_DETAILS + username,
          {
            headers: authHeader(),
          }
        );
        if (
          response.data &&
          Array.isArray(response.data) &&
          response.data.length > 0
        ) {
          const newAvailability = response.data;
          setAvailabilityDetails((currAvailability) =>
            currAvailability !== newAvailability
              ? newAvailability
              : currAvailability
          );
          setLoading(() => false);
        }

        setLoading(() => false);
      }
    } catch (error) {
      toast.warning("Technical Error while fetching the availability Details", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const handleTimeChange = (timeType, time, index) => {
    if (timeType === "start") {
      //need to change the startTime of the current Index.
      const newAvailability = [...availabilityDetails];
      const changingObject = newAvailability[index];

      const [, secondKey] = Object.keys(changingObject);

      changingObject[secondKey].startTime = time;
      newAvailability[index] = changingObject;
      setAvailabilityDetails((currAvail) => {
        return currAvail !== newAvailability ? newAvailability : currAvail;
      });
    } else {
      //need to change the endTime of the current index.
      const newAvailability = [...availabilityDetails];
      const changingObject = newAvailability[index];

      const [, secondKey] = Object.keys(changingObject);
      changingObject[secondKey].endTime = time;
      newAvailability[index] = changingObject;
      setAvailabilityDetails((currAvail) => {
        return currAvail !== newAvailability ? newAvailability : currAvail;
      });
    }
  };

  const handleCheckBox = (event, index) => {
    const { checked, name } = event.target;

    setAvailabilityDetails((prevState) => {
      const newState = prevState.map((week, i) => {
        if (i === index) {
          const updatedWeek = { ...week };
          updatedWeek[name] = checked ? 1 : 0;
          return updatedWeek;
        }
        return week;
      });

      return prevState !== newState ? newState : prevState;
    });
  };
  const saveAvailability = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(
          url.UPDATE_AVAILABILITY_DETAILS + username,
          availabilityDetails,
          {
            headers: authHeader(),
          }
        );
        resolve(response);
        getAvailabilityDetails();
      } catch (error) {
        reject(error);
      }
    });
  };
  const handleSaveButton = () => {
    toast.promise(saveAvailability(), {
      pending: "updating availability",
      success: "Updated successfully",
      error: "Some technical isssue while updating the availability",
    });
  };
  return (
    <div className="availability">
      <div className="header">
        <div className="header-title d-flex flex-row">
          <p className="text-start fs-1 fw-bold">Availability</p>
        </div>

        <div className="header-buttons">
          <div
            className="d-flex flex-row flex-wrap justify-content-start"
            style={{ gap: "1rem" }}
          >
            {headerButtons.map((btn) => (
              <button
                className={`btn btn-outline-secondary rounded-pill hover-shadow text-dark border-dark  ${
                  selectedHeader === btn ? `selected-availability-header` : ""
                }`}
                onClick={() => handleChangeButton(btn)}
                value={btn}
              >
                {btn}
              </button>
            ))}
          </div>
        </div>
      </div>
      <hr />

      <div className="main">
        <div className="availability-main">
          {loading ? (
            <Loader />
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="row availability-tabs">
                  <div className="col-lg-12">
                    <div className="row">
                      <div
                        className="col-lg-4"
                        style={{ paddingLeft: "10px", paddingRight: "10px" }}
                      >
                        <div className="row">
                          <div className="col-lg-12 add-slots">
                            {availabilityDetails
                              ? availabilityDetails.map((week, index) => {
                                  const weekObjectKeys = Object.keys(week);

                                  const [firstKey, secondKey] = weekObjectKeys;

                                  //all the keys of the current week.
                                  //then compare that first key with the weekName.
                                  const weekName =
                                    firstKey.charAt(0).toUpperCase() +
                                    firstKey.slice(1);

                                  return (
                                    <div className="row slot-item">
                                      <div className="col-lg-4">
                                        <label>
                                          <span className="slot-checkboxes me-3">
                                            <input
                                              type="checkbox"
                                              onChange={(event) =>
                                                handleCheckBox(event, index)
                                              }
                                              checked={Boolean(week[firstKey])}
                                              name={firstKey}
                                            />
                                          </span>
                                          <span>{weekName}</span>
                                        </label>
                                      </div>
                                      <div className="col-lg-8 slot-section">
                                        {week[firstKey] === 1 ? (
                                          <div className="slot-selection-box">
                                            <div className="row ">
                                              <div className="col-lg-6 slot-box start-time">
                                                <TimeSelector
                                                  timeType="start"
                                                  onTimeChange={
                                                    handleTimeChange
                                                  }
                                                  selectedTime={
                                                    week[secondKey].startTime
                                                  }
                                                  indexNumber={index}
                                                />
                                              </div>
                                              <div className="col-lg-6 slot-box end-time">
                                                <TimeSelector
                                                  timeType="end"
                                                  onTimeChange={
                                                    handleTimeChange
                                                  }
                                                  selectedTime={
                                                    week[secondKey].endTime
                                                  }
                                                  indexNumber={index}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="slots-unavailable text-center">
                                            unavailable
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })
                              : ""}

                            <div className="row">
                              <div
                                className="col-lg-12 add-slots-header-section d-flex flex-row justify-content-between align-items-center"
                                style={{ padding: "24px" }}
                              >
                                <div className="slots-save-button">
                                  <button
                                    className="btn btn-dark"
                                    type="button"
                                    onClick={handleSaveButton}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <Configuration />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Availability;
