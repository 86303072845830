import React, { useState } from "react";
import "./Configuration.css";
import Select from "react-select";
function Configuration() {
  const timezone = require("../../../Utility/timezone.js");
  const [selectedTimeZone, setSelectedTimeZone] = useState(null);
  const bookingPeriodArray = [
    { label: "1 Week", value: "1 Week" },
    { label: "2 Weeks", value: "2 Weeks" },
    { label: "3 Weeks", value: "3 Weeks" },
    { label: "4 Weeks", value: "4 Weeks" },
    { label: "2 Months", value: "2 Months" },
    { label: "3 Months", value: "3 Months" },
  ];
  const noticePeriodArray = [
    { label: "Minutes", value: "Minutes" },
    { label: "Hours", value: "Hours" },
    { label: "Days", value: "Days" },
    { label: "Weeks", value: "Weeks" },
  ];
  const [selectedNoticePeriod, setSelectedNoticePeriod] = useState(
    noticePeriodArray[0].value
  );
  const [selectedBookingPeriod, setSelectedBookingPeriod] = useState(null);
  const handleChange = (selectedTimeZone) => {
    setSelectedTimeZone(selectedTimeZone);
  };
  const handleBookingPeriod = (selectedBookingPeriod) => {
    setSelectedBookingPeriod(selectedBookingPeriod);
  };
  const handleNoticePeriodDropdown = (event) => {
    setSelectedNoticePeriod(event.target.value);
  };
  return (
    <div className="configuration">
      <div className="configuration-tab">
        <div className="configuration-items">
          <div className="row config-item">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 config-title mt-2 d-flex flex-row">
                  <div className="config-icon">
                    <i className="fa-solid fa-location-dot mx-3"></i>
                  </div>
                  <div>
                    <strong>Timezone</strong>
                    <div className="text-muted">
                      <small>Required for timely communications</small>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 config-input">
                  <Select
                    options={timezone}
                    value={selectedTimeZone}
                    isSearchable={true}
                    onChange={handleChange}
                    // defaultValue={defaultOption}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row config-item">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 config-title mt-2 d-flex flex-row">
                  <div className="config-icon">
                    <i className="fa-solid fa-link mx-3"></i>
                  </div>
                  <div>
                    <strong>Personal meeting link</strong>
                    <div className="text-muted">
                      <small>
                        All your 1:1 meetings will be redirected to this URL
                      </small>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 config-input">
                  <div className="personal-meeting-url">
                    <div className="row">
                      <div className="col-lg-6">
                        <input className="form-control" type="text" />
                      </div>
                      <div className="col-lg-6">
                        <button className="btn btn-outline-secondary rounded">
                          Save
                        </button>
                        <button className="btn btn-outline-warning rounded">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row config-item">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 config-title mt-2 d-flex flex-row">
                  <div className="config-icon">
                    <i className="fa-solid fa-calendar mx-3"></i>
                  </div>
                  <div>
                    <strong>Booking Period</strong>
                    <div className="text-muted">
                      <small>How far in the future can attendees book</small>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 config-input">
                  <Select
                    options={bookingPeriodArray}
                    value={selectedBookingPeriod}
                    isSearchable={true}
                    onChange={handleBookingPeriod}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row config-item">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 config-title mt-2 d-flex flex-row">
                  <div className="config-icon">
                    <i className="fa-solid fa-calendar mx-3"></i>
                  </div>
                  <div>
                    <strong>Notice Period</strong>
                    <div className="text-muted">
                      <small>
                        Set the minimum amount of notice that is required
                      </small>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 config-input">
                  <div className="input-group">
                    <div className="row">
                      <div className="col-lg-8 number-input">
                        <input type="number" className="form-control" />
                      </div>
                      <div className="col-lg-4 dropdown-of-number">
                        <select
                          className="form-control"
                          value={selectedNoticePeriod}
                          onChange={handleNoticePeriodDropdown}
                        >
                          {noticePeriodArray.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row config-item">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 config-title mt-2 d-flex flex-row">
                  <div className="config-icon">
                    <i className="fa-solid fa-calendar mx-3"></i>
                  </div>
                  <div>
                    <strong>Google meet</strong>
                    <div className="text-muted">
                      <small>Use google meet for your 1:1 calls</small>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 config-input"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Configuration;
