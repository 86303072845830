import React from "react";
import "./SkeletonTable.css";

function SkeletonTable() {
  return (
    <div className="skeleton-table">
      {/* Create skeleton rows */}
      <div className="skeleton-row"></div>
      <div className="skeleton-row"></div>
      <div className="skeleton-row"></div>
      {/* You can add as many rows as needed */}
    </div>
  );
}

export default SkeletonTable;
