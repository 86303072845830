import React, { useEffect, useState } from "react";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import authHeader from "../../../headerTokenUtility/auth.header";
import axios from "axios";
import { getUserMachineTime } from "../../../Utility/userMachineTime/userMachineTime";
import { roundTime } from "../../../Utility/userMachineTime/roundTime";
import moment from "moment";
import url from "../../../Utility/URL/url";
import getUsernameFromSession from "../../../Utility/gettingInformation/getUsernameFromSession";
function CalendarAndTimeSlot({ availability, callDetails, reschedule }) {
  const isWeekend = (date) => {
    const day = date.day();
    // 0-> sunday
    //6 -> saturday.

    const options = { weekday: "long" }; // set the 'weekday' option to get the full weekday name
    const weekName = date.$d.toLocaleDateString("en-US", options); // get the week name in English (you can change 'en-US' to your desired locale)
    // console.log(weekName); // output: "Monday" (or the current weekday name depending on the date)

    const week = availability.find((week) => {
      const [firstKey] = Object.keys(week);
      if (firstKey.toLowerCase() === weekName.toLowerCase()) {
        return true;
      }
    });
    if (week[weekName.toLowerCase()]) {
      return false;
    } else {
      return true;
    }
  };
  const [slotError, setSlotError] = useState();
  const [selectDate, setSelectDate] = useState(
    new Date(callDetails.bookingDate)
  );
  const [timeSlot, setTimeSlot] = useState([]);
  const [skeleton, setSkeleton] = useState(true);
  const [selectedTime, setSelectedTime] = useState();
  const username = getUsernameFromSession();
  useEffect(() => {
    let json = getSlotJson();
    let newDate;

    newDate = moment(selectDate).format("YYYY-MM-DD");
    json.date = newDate;

    console.log(newDate);
    console.log(selectDate);
    axios
      .post(url.POST_AVAILABLE_TIME_SLOT, json, {
        headers: authHeader(),
      })
      .then((res) => {
        setTimeSlot((currentTimeSlot) => {
          return currentTimeSlot !== res.data.availableTimeSlots
            ? res.data.availableTimeSlots
            : currentTimeSlot;
        });
        setSkeleton(false);
      })
      .catch((err) => console.log(err));
  }, [selectDate]);

  function handleDateChange(newValue) {
    setSelectDate(newValue.$d);
  }
  function getSlotJson() {
    let json = {};
    json.advisorTimezone = callDetails.timeZone;
    json.date = callDetails.bookingDate;
    json.endTime = getWeekFromAvailability(availability).endTime;
    json.localTime = roundTime(getUserMachineTime());
    json.startTime = getWeekFromAvailability(availability).startTime;
    json.timeInterval = callDetails.callDuration;
    json.userSelectedTimezone = callDetails.timeZone;
    json.userSystemCurrentDate = moment(new Date()).format("YYYY-MM-DD");
    json.username = username;
    return json;
  }
  function getWeekFromAvailability(availability) {
    let weekDayName = moment(selectDate.$d).format("dddd");
    //now availability is an array of json's need to find the right week.

    const startEnd = availability.find((week) => {
      if (week[weekDayName.toUpperCase()]) {
        return true;
      }
    });

    return startEnd ? startEnd[weekDayName.toUpperCase()] : "";
  }

  function handleSubmit() {
    if (selectedTime) {
      // call the api else not call the api.
      let details = { selectedTime: selectedTime, selectedDate: selectDate.$d };
      reschedule(callDetails, details);
      setSlotError(() => "");
    } else {
      setSlotError(() => {
        return "Select the Slot";
      });
    }
  }
  return (
    <div className="modal-body">
      <div className="calendar-container">
        <div className="row">
          <div className="col-lg-12">
            <div className="main-calendar">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  orientation="portrait"
                  openTo="day"
                  value={selectDate}
                  shouldDisableDate={isWeekend}
                  onChange={handleDateChange}
                  minDate={new Date()}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
      </div>

      {timeSlot && (
        <div className="d-flex flex-row flex-wrap justify-content-between mx-4 my-3">
          {skeleton ? (
            <React.Fragment>
              <button
                className="btn btn-lg skeleton"
                style={{ width: "4em", height: "2rem" }}
              ></button>
              <button
                className="btn btn-lg skeleton"
                style={{ width: "4em", height: "2rem" }}
              ></button>
              <button
                className="btn btn-lg skeleton"
                style={{ width: "4em", height: "2rem" }}
              ></button>
              <button
                className="btn btn-lg skeleton"
                style={{ width: "4em", height: "2rem" }}
              ></button>
              <button
                className="btn btn-lg skeleton"
                style={{ width: "4em", height: "2rem" }}
              ></button>
            </React.Fragment>
          ) : timeSlot.length === 0 ? (
            <span className="text-danger">
              All Slots are booked for today you can try booking slots for
              tommorow
            </span>
          ) : (
            <React.Fragment>
              {timeSlot.map((time, index) => {
                return (
                  <div className={`calls-button-${index} m-0 p-0`} key={index}>
                    <button
                      className={`${
                        selectedTime === time
                          ? "btn btn-selected-time mt-2"
                          : "btn btn-light mt-2"
                      }`}
                      value={time}
                      onClick={() => {
                        setSelectedTime(time);
                      }}
                    >
                      {time}
                    </button>
                  </div>
                );
              })}
            </React.Fragment>
          )}
        </div>
      )}
      {slotError ? (
        <div className="text-danger mx-3 my-3 mt-3">{slotError}</div>
      ) : (
        ""
      )}

      <div className="submit-button text-end">
        <button className="btn btn-primary" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
}
export default CalendarAndTimeSlot;
