export function getPriceUtility(currency, priceValue) {
  let finalCurrency;
  let finalPrice;

  if (priceValue === "0" || priceValue === 0) {
    return "FREE";
  } else {
    finalPrice = priceValue;
  }

  if (currency === "INR") {
    finalCurrency = "Rs.";
  } else if (currency === "USD") {
    finalCurrency = "$";
  } else {
    finalCurrency = currency;
  }

  if (Number.isInteger(finalPrice)) {
    finalPrice = finalPrice.toString();
  }
  return finalCurrency + finalPrice;
}
