import React, { useRef } from "react";
import "./ServicePayment.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import url from "../../Utility/URL/url.js";
import constants from "../../Utility/Constants/constant.js";
import { toast } from "react-toastify";

import axios from "axios";
import useRazorpay from "react-razorpay";
import { getPriceUtility } from "../../Utility/Price/getPriceUtility";

const ServicePayment = () => {
  const Razorpay = useRazorpay();
  const [phone, setPhone] = useState();
  const location = useLocation();
  const { state } = location;
  const username = useRef("");
  const email = useRef("");
  const callAbout = useRef("");
  const [isChecked, setIsChecked] = useState(false);
  let paymentFailedStatus = false;
  const navigate = useNavigate();
  const usernameAdvisor = state.username;
  const fullName = state.advisiorInformation.profileData.fullName;

  const [errors, setErrors] = useState({});
  const date = state.userSelectedDate;

  const validateAllFields = () => {
    let errors = {};

    //for username
    if (!username.current.value) errors.username = "Name must be entered";
    //for email
    if (!email.current.value) {
      errors.email = "Email must be entered";
    } else {
      const emailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!emailRegex.test(email.current.value)) {
        errors.email = "Invalid email format";
      }
    }

    //for phone
    if (isChecked) {
      if (!phone) {
        errors.phone = "Enter the phone number for booking details";
      } else {
        errors.phone = "";
      }
    } else {
      errors.phone = "";
    }

    if (state.price > "0") {
      if (phone) {
        errors.phone = "";
      } else {
        errors.phone = "Enter the phone number it must be included";
      }
    }
    return errors;
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleConfirmAndPay = () => {
    //validate the form first.
    let errors = validateAllFields();

    //now check if the errors would have keys with non empty strings as values.

    let keys = Object.keys(errors);
    let count = keys.reduce((acc, curr) => (errors[curr] ? acc + 1 : acc), 0);

    if (count === 0) {
      handlePayment();
    } else {
      setErrors(errors);
    }
  };

  function getCallDetails() {
    return (
      findDayName(date) +
      ", " +
      date.getDate() +
      " | " +
      state.bookingTime +
      " - " +
      getMeetingEndtime(state.bookingTime, state.callDuration)
    );
  }
  const handlePayment = () => {
    if (state.price === "0" || state.price === 0) {
      axios
        .post(url.POST_SERVICE_BOOKING, {
          advisorUsername: state.advisiorInformation.profileData.username,
          platformServiceId: state.platformServiceId,
          bookingDate: state.bookingDate,
          bookingTime: state.bookingTime,
          cxEmail: email.current.value,
          aboutCall: callAbout.current.value,
          cxName: username.current.value,
          phoneNumber: phone,
          paymentStatus: constants.PAYMENT_STATUS_FREE,
          paymentId: null,
          title: state.title,
          callDuration: state.callDuration,
          timeZone: state.timeZone.value,
        })
        .then((response) => {
          toast.success("Booking successfully created", {
            position: toast.POSITION.TOP_CENTER,
          });
          //after toast go to the booking confirmation page.
          const callDetails = getCallDetails();
          const bookingConfirmationData = {
            title: state.title,
            calendar: callDetails,
            advisorInformation: state.advisiorInformation,
          };
          const { bookingId } = response.data;

          navigate(
            `/${state.platformServiceId}/bookingConfirmation/bookingId=${bookingId}`,
            {
              state: bookingConfirmationData,
            }
          );
        })
        .catch((error) => {
          if (error.response.status === 500) {
            {
              toast.error(constants.SOMETHING_WENT_WRONG, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        });
    } else {
      //create order
      axios
        .post(url.POST_PAY_CREATE_ORDER, {
          amount: state.price,
          currency: state.currency,
          platformServiceId: state.platformServiceId,
          name: username.current.value,
          emailId: email.current.value,
          mobileNumber: phone,
          usernameAdvisor: usernameAdvisor,
        })
        .then((res) => {
          // after this i need to send the

          const usernameCustmoer = res.data.usernameCustmr;
          //usernameCustmr will come in response after creating the order
          // usernameCustmr, usernameAdvisor
          if (res.status === 200) {
            const options = {
              key: constants.RAZORPAY_key,
              amount: res.amount,
              currency: state.currency,
              name: constants.COMPANY_NAME,
              description: state.title,
              image: constants.COMPANY_LOGO,
              order_id: res.data.orderId,
              handler: function (response) {
                // This information is displayed after the successfull payment by the user.

                //this api is called after the payment is sucessfull.

                //pay response api called.

                console.log(response);
                axios
                  .post(url.POST_PAYMENT_PAY_RESPONSE, {
                    orderId: response.razorpay_order_id,
                    paymentId: response.razorpay_payment_id,
                    status: constants.PAYMENT_RESPONSE_STATUS_SUCCESS,
                    paymentStatus: constants.PAYMENT_STATUS_PAID,
                    usernameAdvisor: usernameAdvisor,
                    usernameCustmr: usernameCustmoer,
                  })
                  .then((response) => {
                    let paymentStatus = response.data.paymentStatus;
                    let paymentId = response.data.paymentId;

                    if (paymentStatus === "PAID") paymentFailedStatus = true;
                    axios
                      .post(url.POST_SERVICE_BOOKING, {
                        advisorUsername:
                          state.advisiorInformation.profileData.username,
                        platformServiceId: state.platformServiceId,
                        bookingDate: state.bookingDate,
                        bookingTime: state.bookingTime,
                        cxEmail: email.current.value,
                        aboutCall: callAbout.current.value,
                        cxName: username.current.value,
                        phoneNumber: phone,
                        paymentStatus: paymentStatus,
                        paymentId: paymentId,
                        title: state.title,
                        callDuration: state.callDuration,
                        timeZone: state.timeZone.value,
                        usernameAdvisor: usernameAdvisor,
                        usernameCustmr: usernameCustmoer,
                      })
                      .then((response) => {
                        toast.success("Booking successfully created", {
                          position: toast.POSITION.TOP_CENTER,
                        });
                        //after toast go to the booking confirmation page.

                        const callDetails = getCallDetails();
                        const bookingConfirmationData = {
                          title: state.title,
                          calendar: callDetails,
                          advisorInformation: state.advisiorInformation,
                        };
                        const { bookingId } = response.data;
                        navigate(
                          `/${state.platformServiceId}/bookingConfirmation/bookingId=${bookingId}`,
                          {
                            state: bookingConfirmationData,
                          }
                        );
                      })
                      .catch((error) =>
                        toast.warning(
                          "Service can't be created some techinical issue...",
                          {
                            position: toast.POSITION.TOP_RIGHT,
                          }
                        )
                      );
                  })
                  .catch((error) => {
                    toast.warning(error, {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  });
              },
              prefill: {
                name: username.current.value,
                email: email.current.value,
                contact: phone,
              },
              notes: {
                address: "",
              },
              theme: {
                color: "#3399cc",
              },
              modal: {
                ondismiss: async function () {
                  if (!paymentFailedStatus) {
                    const orderId = res.data.orderId;

                    try {
                      const res = await axios.post(
                        url.POST_PAYMENT_PAY_RESPONSE,
                        {
                          orderId: orderId,
                          paymentId: null,
                          status: constants.CANCELED_BY_USER,
                          paymentStatus: constants.CANCELED_BY_USER,
                        }
                      );

                      if (res.status === 200) {
                        toast.warn("Your booking is not created....");
                      }
                    } catch (error) {
                      console.log("I am in error");
                      toast.error("Server error", {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                    }
                  }
                },
              },
            };

            const rzp1 = new Razorpay(options);
            rzp1.open();

            rzp1.on("payment.failed", function (response) {
              paymentFailedStatus = true;
              toast.error("Payment Failed", {
                position: toast.POSITION.TOP_RIGHT,
              });
              const orderId = response.error.metadata.order_id;
              const paymentId = response.error.metadata.payment_id;

              axios
                .post(url.POST_PAYMENT_PAY_RESPONSE, {
                  orderId: orderId,
                  paymentId: paymentId,
                  status: constants.PAYMENT_RESPONSE_STATUS_FAIL,
                  paymentStatus: constants.PAYMENT_STATUS_FAILED,
                })
                .then((response) => {
                  console.log("response after fail api ", response);
                })
                .catch((error) => {
                  /// when it's network issue or something then the api should call
                  // console.log(error);

                  axios
                    .post(url.POST_PAYMENT_PAY_RESPONSE, {
                      orderId: orderId,
                      paymentId: paymentId,
                      status: constants.PAYMENT_RESPONSE_STATUS_ERROR,
                      paymentStatus: constants.PAYMENT_STATUS_FAILED,
                    })
                    .then((res) => console.log(res));
                });
            });
          }
        })
        .catch((error) =>
          toast.warning(error, { position: toast.POSITION.TOP_RIGHT })
        );
    }
  };
  function goBack() {
    navigate(-1);
  }
  const findMonth = (date) => {
    const options = { month: "long" };
    return date.toLocaleDateString("en-US", options);
  };

  const findDayName = (date) => {
    const options = { weekday: "long" };

    return date.toLocaleDateString("en-US", options);
  };

  const getMeetingEndtime = (startTime, minutes) => {
    const moment = require("moment");

    const momentObj = moment(startTime, "hh:mm a");
    momentObj.add(minutes, "minutes");
    const updatedTime = momentObj.format("hh:mm a");
    return updatedTime;
  };

  return (
    <main className="container-fluid service-payments">
      <div className="container">
        <div className="row">
          <div className="mx-auto col-10 col-md-8 col-lg-6">
            <div className="sp-details">
              <div className="row header">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="d-flex flex-row" style={{ gap: "1rem" }}>
                    <div
                      className="service-payments-heading-left-arrow mx-2 Go-back"
                      onClick={goBack}
                    >
                      <i className="fa-solid fa-arrow-left"></i>
                    </div>

                    <div className="service-payments-heading-avatar">
                      <div className="avatar">avatar</div>
                    </div>
                    <div className="service-payments-heading-username">
                      <div className="username">{fullName}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <h3 className="service-title">{state.title}</h3>
                  <div className="service-description">
                    <span className="description-text">Video Meeting</span>{" "}
                    <span className="service-minutes">
                      {state.callDuration} Mins
                    </span>
                  </div>
                </div>

                <div
                  className="col-6"
                  style={{
                    display: "flex",
                    marginTop: "12px",
                    justifyContent: "flex-end",
                  }}
                >
                  <span className="Price">
                    {getPriceUtility(state.currency, state.price)}
                  </span>
                </div>
              </div>

              {/* //meeting information here */}
              <div className="row">
                <div className="col-12">
                  <div className="alert alert-success" role="alert">
                    <div className="alert-content">
                      <div className="row">
                        <div className="col-8 alert-message">
                          <span className="alert-calendar mx-2">
                            <i className="fa-solid fa-calendar"></i>
                          </span>
                          <span className="alert-calendar-information">
                            {findDayName(date)} {date.getDate()}{" "}
                            {findMonth(date)} | {state.bookingTime} -{" "}
                            {getMeetingEndtime(
                              state.bookingTime,
                              state.callDuration
                            )}
                            {"  "}
                            {state.timeZone.label}
                          </span>
                        </div>
                        <div className="col-4 alert-avatar text-end">
                          <span className="edit-link">
                            <i className="fa-solid fa-pen" onClick={goBack}></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row service-form">
                <div className="col-12">
                  <form className="form-for-service">
                    {/* <!-- Input fields --> */}
                    <div className="form-group mt-2 mb-1">
                      <label className="form-label">
                        <sup>
                          <i className="fa-solid fa-asterisk"></i>
                        </sup>{" "}
                        Your Name
                      </label>
                      <input
                        type="text"
                        className="form-control username"
                        id="username"
                        placeholder="Enter your name"
                        name="username"
                        ref={username}
                        required
                      />
                      {errors.username ? (
                        <span className="text-danger">{errors.username}</span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="form-group mt-3 mb-1">
                      <label className="form-label">
                        <sup>
                          <i className="fa-solid fa-asterisk"></i>
                        </sup>{" "}
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control email"
                        id="email"
                        placeholder="Enter your email"
                        name="email"
                        ref={email}
                        required
                      />

                      {errors.email ? (
                        <span className="text-danger">{errors.email}</span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="form-group mt-3 mb-1">
                      <label className="form-label">
                        What is the call about
                      </label>
                      <input
                        type="text"
                        className="form-control call-about"
                        id="call-about"
                        placeholder="I would like to discuss"
                        name="call-about"
                        ref={callAbout}
                        required
                      />
                    </div>

                    <div className="form-group mt-3 mb-1">
                      <label className="form-label">Phone number</label>
                      <div className="country-flag">
                        <PhoneInput
                          country={"in"}
                          value={phone}
                          onChange={(phone) => setPhone(phone)}
                        />
                      </div>
                      <span className="text-danger">
                        {errors.phone ? errors.phone : ""}
                      </span>
                    </div>

                    {/* //If this checkbox is tick then we need to send the details to the user provided mobile number. */}
                    <div className="form-check mt-3 mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label">
                        Recieve Booking Details on Phone
                      </label>
                    </div>
                  </form>
                </div>
              </div>

              <div className="row row-bottom">
                <div
                  className="col-lg-6"
                  style={{ paddingLeft: "7px", paddingRight: "7px" }}
                >
                  <span className="sp-charge">
                    {getPriceUtility(state.currency, state.price)}
                  </span>
                </div>
                <div
                  className="col-lg-6"
                  style={{ paddingLeft: "7px", paddingRight: "7px" }}
                >
                  <button
                    className="btn btn-dark text-center text-white bolder"
                    onClick={handleConfirmAndPay}
                  >
                    {state.price === "0" || state.price === 0
                      ? "Confirm"
                      : "Confirm and Pay"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ServicePayment;
