module.exports = {
  POST_api_auth_signup: "https://advisingbuddy.site/api/auth/signup",
  POST_api_auth_signin: "https://advisingbuddy.site/api/auth/signin",

  // this requireand otp user type.
  POST_OTP_VALIDATION_URL: "https://advisingbuddy.site/api/auth/authenticate/otp",

  //resend requires useremail and role of the user.
  POST_RESEND_OTP_URL: "https://advisingbuddy.site/api/auth/resend/otp",

  //requires to post the email of the user.
  POST_FORGET_PASSWORD_URL: "https://advisingbuddy.site/api/auth/forgot/password",

  // need to post email , new password, confirm password.
  POST_CHANGE_PASSWORD_URL: "https://advisingbuddy.site/api/auth/change/password",

  //Getting the user information
  GET_USER_PROFILE_URL: "https://advisingbuddy.site/useremail",

  //Payment creating order
  POST_PAY_CREATE_ORDER: "https://advisingbuddy.site/public/pay/create/order",

  //GET public profile information(public page)
  GET_USER_PROFILE_INFORMATION:
    "https://advisingbuddy.site/public/profile?username=", //need to add the ?username=dynamic name

  //For Time Slots
  POST_AVAILABLE_TIME_SLOT:
    "https://advisingbuddy.site/public/available/time/slots",

  //POST for create booking.
  POST_SERVICE_BOOKING: "https://advisingbuddy.site/public/service/booking/create",

  POST_USER_AVAILABLE_TIME_SLOT:
    "https://advisingbuddy.site/public/available/time/slots",

  //related payment
  POST_PAYMENT_PAY_RESPONSE: "https://advisingbuddy.site/public/pay/response",

  POST_SERVICE_ADD: "https://advisingbuddy.site/advisor/dashboard/service/add",

  //POST TOGGLE BUTTON
  POST_TOGGLE_VALUE: "https://advisingbuddy.site/advisor/dashboard/service/toggle",

  //GET ALL THE SERVICE
  GET_ALL_SERVICE: "https://advisingbuddy.site/advisor/dashboard/service",

  //DELETE THE SERVICE
  DELETE_SERVICE_URL:
    "https://advisingbuddy.site/advisor/dashboard/service/delete",

  //GET SERVICE LIST BY TYPE
  GET_SERVICE_BY_USERNAME_AND_TYPE:
    "https://advisingbuddy.site/advisor/dashboard/service",

  // GET SERVICE INFORMATION USING USERNAME AND IT"S PLATFORMSERVICEID
  GET_SERVICE_BY_USERNAME_AND_PLATFORMSERVICEID:
    "https://advisingbuddy.site/public/service/", // PLATFORMID + ?username = "username"
  //Updating service id
  PUT_SERVICE_BY_SERVICE_ID:
    "https://advisingbuddy.site/advisor/dashboard/service/update/",

  //CREATE_QUERY
  POST_QUERY_TO_ADVISOR: "https://advisingbuddy.site/public/service/query/create",

  //RESPONSE_FOR_QUERY
  PUT_RESPONSE_QUERY:
    "https://advisingbuddy.site/advisor/dashboard/query/response",

  //EDIT RESPONSE FOR QUERY
  POST_EDIT_RESPONSE_QUERY:
    "https://advisingbuddy.site/advisor/dashboard/query/edit",

  //PROFILE DETAILS ON USERPARAMS
  GET_PROFILE_DETAILS: "https://advisingbuddy.site/advisor/dashboard/profile/",

  //GET_ALL_QUERY
  GET_ALL_QUERIES: "https://advisingbuddy.site/advisor/dashboard/query/get/all/",

  //GET PENDING QUERIES
  GET_PENDING_QUERIES:
    "https://advisingbuddy.site/advisor/dashboard/query/get/pending/",

  //GET PENDING QUERIES
  GET_COMPLETED_QUERIES:
    "https://advisingbuddy.site/advisor/dashboard/query/get/completed/",

  //PROFILE SECTION API DASHBOARD//
  PUT_UPDATE_PROFILE_DETAILS:
    "https://advisingbuddy.site/advisor/dashboard/profile/update",

  //AVAILABILITY SECTION API HERE//
  GET_AVAILABILITY_DETAILS:
    "https://advisingbuddy.site/advisor/dashboard/availability/get/",

  UPDATE_AVAILABILITY_DETAILS:
    "https://advisingbuddy.site/advisor/dashboard/availability/update/",

  //Calls section here
  GET_ALL_CALLS: "https://advisingbuddy.site/advisor/dashboard/calls/all/",

  //GET_PENDING_CALLS:
  GET_PENDING_CALLS: "https://advisingbuddy.site/advisor/dashboard/calls/pending/",

  //GET_COMPLETED_CALLS:
  GET_COMPLETED_CALLS:
    "https://advisingbuddy.site/advisor/dashboard/calls/completed/",

  //Mark_AS_Complete_CALL:
  POST_MARK_AS_COMPLETED:
    "https://advisingbuddy.site/advisor/dashboard/calls/mark/completed/",

  //Delete Call :
  DELETE_CALL_SERVICE:
    "https://advisingbuddy.site/advisor/dashboard/calls/cancel/",

  //Rescedule call:
  RESCHEDULE_CALL:
    "https://advisingbuddy.site/advisor/dashboard/calls/re-schedule",

  //GET ALL TRANSACTIONS:
  GET_ALL_TRANSACTIONS_DETAILS:
    "https://advisingbuddy.site/advisor/dashboard/payment/get/all/transactions/",

  //GET_ALL_WITHDRAWAL_TRANSACTIONS_DETAILS:
  GET_ALL_WITHDRAWALS_DETAILS:
    "https://advisingbuddy.site/advisor/dashboard/payment/withdrawal/all/",

  //GET_ALL_SERVICE_BOOKINGS:
  GET_ALL_SERVICE_BOOKINGS_DETAILS:
    "https://advisingbuddy.site/advisor/dashboard/payment/get/bookings/",
    
  //Landing Page Post API Call
  POST_USER_ON_LANDINGPAGE: 'https://advisingbuddy.site/public/land',
};
