import React from "react";
import "./FourZeroFour.css";
function FourZeroFour() {
  return (
    <div className="four-zero-four">
      <div style={{ textAlign: "center", alignSelf: "center" }}>
        <div>
          <img src={require("./404.png")} width={"300px"} />
          <div style={{ color: "white", paddingTop: "20px", fontSize: "40px" }}>
            404
          </div>
          <div
            style={{
              color: "#4f5a69",
              padding: "10px 20px 0px 20px",
              fontSize: "16px",
            }}
          >
            Oops! We could not find the webpage you are looking for.
          </div>
        </div>
      </div>
    </div>
  );
}
export default FourZeroFour;
