module.exports = {
  ONE_ON_ONE_CALL: "ONE_ON_ONE_CALL",
  WEBINAR: "WEBINAR",
  TEXT_QUERY: "TEXT_QUERY",
  RAZORPAY_key: "rzp_test_yq5te40VGqTPOW",
  COMPANY_NAME: "ADVISING BUDDY",
  CANCELED_BY_USER: "CANCELED_BY_USER",
  COMPANY_LOGO: "https://example.com/your_logo",
  REACH_OUT_TO_ME_DEFAULT:
    "Hey! Just created my profile on AdvisingBuddy.com stay tuned for more update",
  PAYMENT_RESPONSE_STATUS_SUCCESS: "SUCCESS",
  PAYMENT_RESPONSE_STATUS_FAIL: "FAIL",
  PAYMENT_RESPONSE_STATUS_ERROR: "ERROR",
  PAYMENT_STATUS_PAID: "PAID",
  PAYMENT_STATUS_FAILED: "FAILED",
  PAYMENT_STATUS_FREE: "FREE",

  SOMETHING_WENT_WRONG: "Something Went Wrong , Please try again later!",

  ALL_TRANSACTIONS: "All transactions",

  WITHDRAWAL: "withdrawal",

  SERVICE_BOOKINGS: "Service Booking",

  ABD_SESSION: "abd_session",
  FEATURE_DATA: [
    {
      "feature": "Seamless Scheduling & Control",
      "content": [
        {
          "title": "1:1 Personalized Sessions",
          "discription": "Connect Directly with Your Audience for Tailored Conversations"
        },
        {
          "title": "Set Your Availability",
          "discription": "Manage Your Schedule with Flexible, Hassle-Free Booking"
        },
        {
          "title": "No Call Overlaps, Ever",
          "discription": "Smart Scheduling Ensures Your Calls Never Clash or Overlap"
        },
        {
          "title": "Public and Private Event Control",
          "discription": "Seamlessly Organize and Toggle Between Public and Private Events"
        }
      ],
      "color": 'blue'
    },
    {
      "feature": "Communication & Interaction",
      "content": [
        {
          "title": "Priority Direct Messages",
          "discription": "Stay at the Top of Your Audience’s Inbox with Exclusive Messaging"
        },
        {
          "title": "Automated Reminders",
          "discription": "Never Miss a Meeting—Get Automated Reminders Before Every Session"
        },
        {
          "title": "Timezone Flexibility",
          "discription": "Customers See Your Availability in Their Timezone—Powered by Smart Zone Conversion"
        },
        {
          "title": "Integrated Calendar Sync",
          "discription": "Sync Your Calendar for Real-Time Availability Updates"
        }
      ],
      "color": 'red'
    },
    {
      "feature": "Payments & Analytics",
      "content": [
        {
          "title": "Effortless Global Payments",
          "discription": "Seamless Transactions with Integrated Razorpay and Stripe—No Currency Worries!"
        },
        {
          "title": "Track Payments Data",
          "discription": "Get Real-Time Data on Users Who Booked and Those Who Dropped During Payment"
        },
        {
          "title": "Hassle-Free Withdrawals",
          "discription": "Effortlessly Withdraw Your Earnings with a Few Clicks"
        }
      ],
      "color": 'yellow'
    },
    {
      "feature": "Feedback & Integration",
      "content": [
        {
          "title": "Feedback and Ratings System",
          "discription": "Gather Valuable Insights with Post-Session Ratings and Reviews"
        },
        {
          "title": "Video Conference Platform Integration",
          "discription": "Host Sessions on Your Preferred Platform or Use the Built-in Video Conferencing Tool"
        },
        {
          "title": "Email Marketing Integration",
          "discription": "Connect with Your Audience via Automated Email Campaigns"
        }
      ],
      "color": 'green'
    }
  ],
  FEATURE_ICON_MAPPING: {
    "1:1 Personalized Sessions": "fas fa-user",
    "Set Your Availability": "fas fa-calendar-alt",
    "No Call Overlaps, Ever": "fas fa-stopwatch",
    "Public and Private Event Control": "fas fa-users",
    "Priority Direct Messages": "fas fa-envelope",
    "Automated Reminders": "fas fa-bell",
    "Timezone Flexibility": "fas fa-clock",
    "Integrated Calendar Sync": "fas fa-sync-alt",
    "Effortless Global Payments": "fas fa-money-bill-wave",
    "Track Payments Data": "fas fa-chart-line",
    "Hassle-Free Withdrawals": "fas fa-hand-holding-usd",
    "Feedback and Ratings System": "fas fa-star",
    "Video Conference Platform Integration": "fas fa-video",
    "Email Marketing Integration": "fas fa-envelope-open-text"
  }
};
