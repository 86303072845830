import React from "react";
import { format, addMinutes } from "date-fns";

const TimeSelector = ({
  timeType,
  selectedTime,
  onTimeChange,
  indexNumber,
}) => {
  const handleTimeChange = (event) => {
    const time = event.target.value;
    onTimeChange(timeType, time, indexNumber);
  };

  // Generate an array of time options
  const timeOptions = [];
  const startTime = new Date(0);
  startTime.setHours(0, 0, 0, 0); // Set start time to 12:00 A.M.

  const endTime = new Date(0);
  endTime.setHours(23, 45, 0, 0); // Set end time to 24:00 P.M.

  const interval = 15; // 15 minutes interval
  let currentTime = new Date(startTime);

  while (currentTime <= endTime) {
    const formattedTime = format(currentTime, "hh:mm aa").toLowerCase();
    timeOptions.push(formattedTime);

    currentTime = addMinutes(currentTime, interval);
  }

 
  return (
    <div>
      <select
        className="form-select"
        id={`${timeType}-time`}
        value={selectedTime}
        onChange={handleTimeChange}
      >
        {timeOptions.map((time) => (
          <option key={time} value={time}>
            {time}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TimeSelector;
