import constant from "../Utility/Constants/constant";
import gettingDetails from "../Utility/DataFetching/gettingDetails";

export default function authHeader() {
  const value = gettingDetails(constant.ABD_SESSION);

  const object = JSON.parse(value);

  if (object && object.accessToken) {
    return { Authorization: "Bearer " + object.accessToken };

    // return { "x-auth-token": user.accessToken };
  } else {
    return {};
  }
}
